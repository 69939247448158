import { Layout, SelectOption } from "@visma-netvisor/react-component-library";
import OrderSummary from "../Components/OrderSummary/OrderSummary";
import Header from "../Components/Header";
import OrderConfirmation from "./OrderConfirmation/OrderConfirmation";
import { useContext, useState } from "react";
import styled from "styled-components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { t } from "i18next";
import { Packages } from "../globalTypes";
import { getUrlParam } from "../utils";
import { getPriceByPacket, getRangeData } from "../Domain/PriceTable";
import { COMPANY_CREATION_URL, strings } from "../constants";
import { sendTag } from "../analytics";
import { useToast } from "../context/Toast";
import { getLanguageId } from "../i18";
import { getPacketId, getPacketName } from "../Domain/NetvisorPacket";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import { PricesContext } from "../pricesContext";
import { logFrontendError } from "./LogFrontendError";
import {
  getTradeRegistrationYear,
  isNewCampaignActivated,
} from "../Domain/NewCampaign";
import {
  UpdateLead,
  UpdateLeadVariables,
  UpdateLead_updateLead,
} from "./__generated__/UpdateLead";
import { getTrackingInfo } from "../utils/tracking";

const UPDATE_LEAD = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      __typename
      ... on UpdatedLeadResult {
        lead
      }
    }
  }
`;

export type FormInputs = {
  businessIdentityCode: string;
  companyForm: SelectOption<string> | undefined;
  companyName: string;
  address: string;
  postalCode: string;
  city: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  privacyPolicyAgreementCheckbox: boolean;
  termsOfServiceCheckbox: boolean;
  authorizedRepresentativeCheckbox: boolean;
  survey: SelectOption<string> | undefined;
  surveyOther: string;
};

const Content = () => {
  const [updateLead] = useMutation<UpdateLead, UpdateLeadVariables>(
    UPDATE_LEAD
  );

  const { addToast } = useToast();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const languageId = getLanguageId(language);
  const prices = useContext(PricesContext);

  const informationProcessingConsentText =
    t("UserInformation.FirstConfirm1") +
    t("UserInformation.PrivacyStatement") +
    t("UserInformation.FirstConfirm2");

  const form = useForm<FormInputs>({
    mode: "onBlur",
    defaultValues: {
      businessIdentityCode:
        sessionStorage.getItem("businessIdentityCode") ?? "",
      companyForm: undefined,
      companyName: sessionStorage.getItem("companyName") ?? "",
      address: sessionStorage.getItem("address") ?? "",
      postalCode: sessionStorage.getItem("postalCode") ?? "",
      city: sessionStorage.getItem("city") ?? "",
      firstName: sessionStorage.getItem("firstName") ?? "",
      lastName: sessionStorage.getItem("lastName") ?? "",
      email: sessionStorage.getItem("email") ?? "",
      phone: sessionStorage.getItem("phone") ?? "",
      privacyPolicyAgreementCheckbox: false,
      termsOfServiceCheckbox: false,
      authorizedRepresentativeCheckbox: false,
      survey: undefined,
      surveyOther: "",
    },
  });

  const { handleSubmit, formState, watch } = form;
  const { isValid } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (input) => {
    setIsLoading(true);

    const currentValues = watch();
    for (const [key] of Object.entries(currentValues)) {
      sessionStorage.removeItem(key);
    }

    const incomeSize: number = Number(getUrlParam("turnover"));
    const orderPrice = getPriceByPacket(prices, getUrlParam("packet"));
    const turnoverCategory = getRangeData(incomeSize)?.nvFormat ?? "";

    try {
      const res = await updateLead({
        variables: {
          input: {
            id: sessionStorage.getItem("leadId") ?? "",
            selectedPackage: getPacketName()
              .toString()
              .toUpperCase() as Packages,
            turnoverCategory: turnoverCategory,
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            phoneNumber: input.phone,
            companyName: input.companyName,
            companyStreetAddress: input.address,
            companyCity: input.city,
            companyForm: input.companyForm?.label as string,
            informationProcessingConsentText: informationProcessingConsentText,
            cmrr: orderPrice,
            landingPage: getTrackingInfo(strings.TRACKING_LANDINGPAGE),
            organicSource: getTrackingInfo(strings.TRACKING_ORGANICSOURCE),
            organicSourceName: getTrackingInfo(
              strings.TRACKING_ORGANICSOURCENAME
            ),
            trafficSource: getTrackingInfo(strings.TRACKING_TRAFFICSOURCE),
            firstTrafficSource: getTrackingInfo(
              strings.TRACKING_FIRSTTRAFFICSOURCE
            ),
            utmCampaign: getTrackingInfo(strings.TRACKING_UTMCAMPAIGN),
            utmTerm: getTrackingInfo(strings.TRACKING_UTMTERM),
            utmMedium: getTrackingInfo(strings.TRACKING_UTMMEDIUM),
            utmSource: getTrackingInfo(strings.TRACKING_UTMSOURCE),
            utmContent: getTrackingInfo(strings.TRACKING_UTMCONTENT),
            survey: input.survey?.value ?? "",
            surveyOther: input.surveyOther,
            isNewCompanyCampaign: isNewCampaignActivated(),
          },
        },
      });

      if (isSuccessResponse(res.data!.updateLead)) {
        sendTag({ status: "validFormSent" });
        const leadId = res.data!.updateLead.lead;
        sessionStorage.setItem("leadId", leadId);
        addToast("Tilaus vahvistettu", "success");
        authenticate(input, leadId, languageId);
      } else {
        setIsLoading(false);
        throw new Error("Lead creation failed.");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      throw new Error("Lead creation failed.");
    }
  };

  return (
    <>
      <Layout.Flex
        flexDirection="column"
        flexGap={0}
        styleOverrides={{
          minHeight: "120vh",
        }}
      >
        <Header />

        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (isValid) {
                handleSubmit(onSubmit)(e).catch(async (error) => {
                  await logFrontendError({
                    info: "Order submit failed",
                    error,
                  });
                  setError("Submit failed");
                });
              }
            }}
          >
            <StyledMainFlex>
              <StyledLayoutFlex>
                <Layout.Item columnsMd="span 7" className="order-confirmation">
                  <OrderConfirmation
                    error={error}
                    setError={setError}
                    isLoading={isLoading}
                  />
                </Layout.Item>
                <Layout.Item columnsMd="span 5" className="order-summary">
                  <OrderSummary />
                </Layout.Item>
              </StyledLayoutFlex>
            </StyledMainFlex>
          </form>
        </FormProvider>
      </Layout.Flex>
    </>
  );
};

export default Content;

function authenticate(
  formInputs: FormInputs,
  leadId: string,
  languageId: number
) {
  const incomeSize: number = Number(getUrlParam("turnover"));
  const turnoverGroupIdentifier = getRangeData(incomeSize)?.nvPacketIdentifier;

  const data = {
    SelectedPacketIdentifier: getPacketId(),
    TurnoverGroupIdentifier: turnoverGroupIdentifier,
    FirstName: formInputs.firstName,
    LastName: formInputs.lastName,
    Email: formInputs.email,
    PhoneNumber: formInputs.phone,
    OrganizationIdentifier: formInputs.businessIdentityCode,
    CompanyName: formInputs.companyName,
    Address: formInputs.address,
    ZipCode: formInputs.postalCode,
    City: formInputs.city,
    AccountListTypeIdentifier: formInputs.companyForm?.value,
    LeadId: leadId,
    LanguageId: languageId,
    TradeRegistrationYear: getTradeRegistrationYear(),
  };

  const encodedData = Buffer.from(JSON.stringify(data), "utf8").toString(
    "base64"
  );

  const form = document.createElement("form");

  form.method = "POST";
  form.action = `${COMPANY_CREATION_URL}/CompanyCreation`;

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = "data";
  input.value = encodedData;
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
}

function isSuccessResponse(
  response: UpdateLead_updateLead
): response is UpdateLead_updateLead {
  return response.__typename === "UpdatedLeadResult";
}

const StyledLayoutFlex = styled(Layout.Flex)`
  display: flex;
  gap: 64px;
  overflow: auto;

  .order-confirmation {
    order: 1;
  }

  .order-summary {
    order: 2;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    display: inline-grid;
    gap: 16px;

    .order-confirmation {
      order: 2;
    }

    .order-summary {
      order: 1;
      overflow: hidden;
    }
  }
`;
const StyledMainFlex = styled(Layout.Flex)`
  as: main;
  justify-content: center;
  background-color: #ffffff;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (min-width: 769px) {
    padding: 48px;
  }
`;
