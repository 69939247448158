import React, { SVGProps } from "react";

const NetvisorWhiteLogo: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="150"
      height="35"
      viewBox="0 0 400 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.406 25.1689C109.304 24.6228 111.77 24.1027 114.808 23.6103C117.844 23.1178 121.206 22.8719 124.894 22.8719C128.364 22.8719 131.265 23.3514 133.598 24.3079C135.929 25.265 137.787 26.6049 139.17 28.3274C140.553 30.0506 141.528 32.13 142.098 34.5632C142.667 36.997 142.952 39.6638 142.952 42.5622V66.6825H133.11V44.1209C133.11 41.8239 132.96 39.869 132.662 38.2549C132.363 36.642 131.875 35.3293 131.198 34.3167C130.519 33.3053 129.598 32.567 128.433 32.1017C127.266 31.6376 125.843 31.404 124.162 31.404C122.914 31.404 121.612 31.4866 120.258 31.6506C118.902 31.8145 117.899 31.9519 117.248 32.0604V66.6826H107.406V25.1689Z"
        fill="white"
      />
      <path
        d="M150.475 45.4336C150.475 41.6593 151.031 38.351 152.143 35.5062C153.254 32.6631 154.732 30.2965 156.576 28.41C158.419 26.5229 160.533 25.1011 162.92 24.1434C165.306 23.1875 167.746 22.708 170.241 22.708C176.098 22.708 180.665 24.5409 183.947 28.2048C187.227 31.8693 188.868 37.3397 188.868 44.6133C188.868 45.1606 188.854 45.7756 188.827 46.4591C188.799 47.1438 188.759 47.7589 188.705 48.305H160.643C160.913 51.7507 162.12 54.4175 164.263 56.304C166.404 58.1911 169.509 59.1347 173.576 59.1347C175.961 59.1347 178.143 58.9165 180.124 58.4783C182.103 58.0413 183.662 57.576 184.801 57.0836L186.102 65.2059C185.56 65.4801 184.813 65.7673 183.866 66.0675C182.916 66.3682 181.832 66.6412 180.612 66.8878C179.392 67.1337 178.076 67.3389 176.667 67.5028C175.256 67.6668 173.82 67.7494 172.356 67.7494C168.614 67.7494 165.361 67.1879 162.595 66.0675C159.829 64.947 157.552 63.3878 155.762 61.3909C153.973 59.3947 152.643 57.0423 151.777 54.3349C150.908 51.6275 150.475 48.6612 150.475 45.4336V45.4336ZM179.026 41.003C179.026 39.6366 178.835 38.3368 178.456 37.1061C178.076 35.8753 177.521 34.8091 176.789 33.9068C176.057 33.004 175.162 32.294 174.104 31.7732C173.047 31.2543 171.786 30.9942 170.322 30.9942C168.803 30.9942 167.475 31.2814 166.336 31.8558C165.197 32.4296 164.234 33.1821 163.449 34.1115C162.662 35.0421 162.052 36.1089 161.619 37.3113C161.184 38.515 160.887 39.7457 160.724 41.003H179.026V41.003Z"
        fill="white"
      />
      <path
        d="M196.547 12.4527L206.389 10.8115V23.7742H221.518V32.0604H206.389V49.5357C206.389 52.9815 206.93 55.443 208.016 56.9197C209.1 58.3963 210.944 59.1347 213.547 59.1347C215.336 59.1347 216.923 58.9436 218.305 58.5603C219.688 58.1782 220.786 57.8219 221.6 57.4941L223.227 65.3698C222.088 65.8622 220.596 66.3682 218.753 66.8878C216.909 67.4067 214.74 67.6668 212.246 67.6668C209.208 67.6668 206.674 67.2569 204.64 66.4366C202.607 65.6157 200.992 64.4263 199.8 62.8676C198.607 61.309 197.767 59.4219 197.279 57.2069C196.791 54.9913 196.547 52.4626 196.547 49.6177V12.4527V12.4527Z"
        fill="white"
      />
      <path
        d="M242.013 66.6825C239.291 61.0495 236.5 54.5814 233.641 47.2794C230.782 39.9774 228.183 32.1424 225.841 23.7742H236.338C236.937 26.1815 237.631 28.7662 238.42 31.5273C239.21 34.2896 240.053 37.0525 240.952 39.8135C241.85 42.5764 242.776 45.2567 243.729 47.8538C244.682 50.4521 245.567 52.7904 246.384 54.8686C247.2 52.7904 248.072 50.4521 248.997 47.8538C249.922 45.2567 250.835 42.5764 251.733 39.8135C252.631 37.0525 253.489 34.2896 254.306 31.5273C255.122 28.7662 255.83 26.1815 256.43 23.7742H266.6C264.258 32.1424 261.658 39.9774 258.8 47.2794C255.94 54.5814 253.15 61.0495 250.428 66.6825H242.013H242.013Z"
        fill="white"
      />
      <path
        d="M283.133 11.2219C283.133 13.0819 282.536 14.5586 281.343 15.6519C280.15 16.7471 278.74 17.2931 277.114 17.2931C275.432 17.2931 273.995 16.7471 272.802 15.6519C271.609 14.5586 271.013 13.0819 271.013 11.2219C271.013 9.30767 271.609 7.8045 272.802 6.70933C273.995 5.61601 275.432 5.06873 277.114 5.06873C278.74 5.06873 280.15 5.61601 281.343 6.70933C282.536 7.8045 283.133 9.30767 283.133 11.2219ZM282.075 66.6825H272.233V23.7742H282.075V66.6825V66.6825Z"
        fill="white"
      />
      <path
        d="M303.451 59.5451C306.054 59.5451 307.951 59.2308 309.144 58.6016C310.336 57.9729 310.934 56.8925 310.934 55.3604C310.934 53.9392 310.296 52.7633 309.023 51.8327C307.748 50.9033 305.647 49.8919 302.719 48.7974C300.929 48.141 299.288 47.4434 297.797 46.705C296.305 45.9667 295.018 45.1051 293.934 44.1209C292.849 43.1366 291.994 41.9465 291.372 40.5518C290.747 39.1571 290.436 37.4487 290.436 35.4242C290.436 31.4866 291.872 28.3829 294.747 26.1124C297.621 23.8438 301.525 22.708 306.46 22.708C308.954 22.708 311.341 22.9409 313.618 23.405C315.896 23.8703 317.604 24.3215 318.743 24.759L316.953 32.7987C315.868 32.3069 314.485 31.8558 312.805 31.4453C311.123 31.0349 309.171 30.8303 306.948 30.8303C304.942 30.8303 303.314 31.1723 302.068 31.8558C300.82 32.5399 300.197 33.5925 300.197 35.0144C300.197 35.7256 300.319 36.3548 300.563 36.9009C300.807 37.4488 301.227 37.9547 301.824 38.4188C302.42 38.8841 303.207 39.3494 304.183 39.8135C305.159 40.2788 306.351 40.7571 307.762 41.2495C310.093 42.1247 312.073 42.9862 313.7 43.8337C315.326 44.6823 316.669 45.6388 317.726 46.705C318.783 47.7718 319.556 48.9897 320.044 50.356C320.532 51.7236 320.777 53.3648 320.777 55.2784C320.777 59.3806 319.272 62.4855 316.262 64.5902C313.252 66.6955 308.954 67.7494 303.369 67.7494C299.627 67.7494 296.618 67.4338 294.341 66.8058C292.063 66.1772 290.463 65.6712 289.541 65.2878L291.25 57.0016C292.714 57.6038 294.462 58.1782 296.496 58.7242C298.529 59.2721 300.848 59.5451 303.451 59.5451V59.5451Z"
        fill="white"
      />
      <path
        d="M366.627 45.1877C366.627 48.5792 366.139 51.6687 365.163 54.4581C364.186 57.2475 362.804 59.6271 361.014 61.5962C359.225 63.5647 357.069 65.0968 354.548 66.1901C352.026 67.2841 349.247 67.8313 346.21 67.8313C343.173 67.8313 340.407 67.2841 337.914 66.1901C335.419 65.0968 333.277 63.5647 331.487 61.5962C329.698 59.6271 328.301 57.2475 327.298 54.4581C326.294 51.6687 325.794 48.5792 325.794 45.1877C325.794 41.7968 326.294 38.7202 327.298 35.9579C328.301 33.1962 329.711 30.8303 331.528 28.8612C333.344 26.8921 335.5 25.3741 337.995 24.3079C340.489 23.2411 343.227 22.708 346.21 22.708C349.192 22.708 351.945 23.2411 354.467 24.3079C356.988 25.3741 359.143 26.8921 360.933 28.8612C362.722 30.8303 364.118 33.1962 365.122 35.9579C366.125 38.7202 366.627 41.7968 366.627 45.1877V45.1877ZM356.54 45.1877C356.54 40.921 355.632 37.5449 353.816 35.055C351.998 32.567 349.464 31.3221 346.21 31.3221C342.957 31.3221 340.421 32.567 338.605 35.055C336.788 37.5449 335.88 40.921 335.88 45.1877C335.88 49.5086 336.788 52.9279 338.605 55.443C340.421 57.9594 342.957 59.2166 346.21 59.2166C349.464 59.2166 351.998 57.9594 353.816 55.443C355.632 52.9279 356.54 49.5086 356.54 45.1877V45.1877Z"
        fill="white"
      />
      <path
        d="M397.292 32.7168C396.478 32.4437 395.352 32.1565 393.916 31.8558C392.478 31.5556 390.811 31.404 388.914 31.404C387.828 31.404 386.677 31.5144 385.456 31.7325C384.236 31.9519 383.382 32.1424 382.894 32.3069V66.6825H373.052V25.8252C374.95 25.1153 377.322 24.4447 380.169 23.8155C383.016 23.1875 386.174 22.8719 389.646 22.8719C390.296 22.8719 391.055 22.9126 391.923 22.9952C392.79 23.0771 393.658 23.1875 394.526 23.3231C395.392 23.4605 396.234 23.6244 397.048 23.8155C397.861 24.0078 398.512 24.1846 399 24.3486L397.292 32.7168V32.7168Z"
        fill="white"
      />
      <path
        d="M66.573 1L56.5486 11.0968C50.7393 16.7568 49.6015 26.3752 53.9264 33.2553L53.935 33.2688C55.8748 36.4538 57.8334 39.6714 59.6431 42.8626C62.3462 47.8738 69.3042 59.4538 64.7049 64.1704L84.5227 44.1886L84.5229 44.1884L84.5231 44.1882L84.5239 44.1875C91.1884 37.5588 75.4625 17.2146 66.573 1V1Z"
        fill="white"
      />
      <path
        d="M35.5394 59.0503C35.1474 56.8322 34.3438 54.7051 33.1687 52.7829C33.1597 52.7668 33.1411 52.7337 33.1322 52.7178C23.0837 46.6987 8.39537 36.3745 2.55796 41.88C-0.672975 45.3136 1.87493 51.3124 3.45716 55.0899C8.24087 65.5121 15.0813 74.9538 20.5771 85L30.605 74.8858C34.6929 70.8054 36.5749 64.7386 35.5394 59.0503V59.0503Z"
        fill="white"
      />
      <path
        d="M25.1445 44.2205C31.4333 47.9424 44.8512 56.5208 49.5636 58.8085C52.7753 60.449 59.5555 63.9605 62.414 62.1051C64.8953 60.1157 60.5147 51.0323 60.654 51.4461L60.6273 51.3916C60.6018 51.3367 58.0313 45.8256 53.7567 38.9401C52.5175 36.9437 50.2808 33.1452 48.7816 30.0779C39.9246 24.8745 27.1629 16.4962 22.0468 22.2718L22.0462 22.2725C18.3643 25.9686 10.8335 33.5536 7.2038 37.2336C12.7284 37.3039 19.9577 41.2359 25.1445 44.2205V44.2205Z"
        fill="white"
      />
    </svg>
  );
};

export default NetvisorWhiteLogo;
