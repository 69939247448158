import {
  Layout,
  Input,
  Spinner,
  Typography,
  InputBlock,
} from "@visma-netvisor/react-component-library";
import { COMPANY_INFORMATION_URL, strings } from "../../constants";
import { useTranslation } from "react-i18next";
import { companyFormOptions } from "./CompanyFormSelect";
import { CompanyForm } from "../../Domain/CompanyForm";
import { MagnifierIcon } from "@visma-netvisor/nv-react-icons";
import { useEffect, useState } from "react";
import { sendTag } from "../../analytics";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { logFrontendError } from "../LogFrontendError";
import { gql, useMutation } from "@apollo/client";
import {
  CreateLead,
  CreateLeadVariables,
  CreateLead_createLead,
} from "./__generated__/CreateLead";
import { getPacketName } from "../../Domain/NetvisorPacket";
import { Packages } from "../../globalTypes";
import { getRangeData } from "../../Domain/PriceTable";
import { getUrlParam } from "../../utils";
import { isNewCampaignActivated } from "../../Domain/NewCampaign";
import { getTrackingInfo } from "../../utils/tracking";

type Props = {
  setIsSupportedCompanyForm: (value: boolean) => void;
  setCompanyFound: (value: boolean) => void;
  setSearchingCompany: (value: boolean) => void;
  setConnectionError: (value: boolean) => void;
  setError: (error: string | undefined) => void;
};

type CompanyRecord = {
  businessId?: string;
  name?: string;
  companyForm?: string;
  companyInformation?: CompanyInformation;
};

type CompanyInformation = {
  street?: string;
  city?: string;
  postCode?: string;
  tradeRegisterDate?: string;
};

const REQUEST_URL = (organizationIdentifier: string) => {
  return `${COMPANY_INFORMATION_URL}?organizationIdentifier=${organizationIdentifier}`;
};

const CREATE_LEAD = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      __typename
      ... on CreatedLeadResult {
        id
      }
    }
  }
`;

export const SelectCompany = ({
  setSearchingCompany,
  setCompanyFound,
  setConnectionError,
  setIsSupportedCompanyForm,
  setError,
}: Props) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
    clearErrors,
  } = useFormContext<FormInputs>();

  const [showLoading, setShowLoading] = useState(false);
  const [createLead] = useMutation<CreateLead, CreateLeadVariables>(
    CREATE_LEAD
  );

  const getCompanyData = async (value: string) => {
    let fetchedData: CompanyRecord = {};

    setSearchingCompany(true);
    setCompanyFound(false);
    setConnectionError(false);

    if (value.length == 9) {
      await fetch(REQUEST_URL(value))
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            fetchedData = data.results[0];
          } else {
            throw new Error("Fetch from Api could not return any value");
          }
        })
        .catch(async (error) => {
          await logFrontendError("YTJ Connection Error");
          sendTag({ status: "companyNotFoundFromYtj" });
          setCompanyFound(false);
          setConnectionError(true);
        });
    }

    const companyName = fetchedData?.name;
    const businessId = fetchedData?.businessId;
    const companyStreet = fetchedData?.companyInformation?.street ?? "";
    const postCode = fetchedData?.companyInformation?.postCode ?? "";
    const city = fetchedData?.companyInformation?.city ?? "";

    const companyFound =
      fetchedData?.name !== undefined && fetchedData?.name !== null;

    setValue("companyName", companyName ?? "");
    setValue("address", companyStreet);
    setValue("postalCode", postCode);
    setValue("city", city);

    const companyForm = await setCompanyFormToSelectOptions(
      fetchedData?.companyForm ?? ""
    );

    if (companyFound) {
      if (
        companyName === undefined ||
        businessId === undefined ||
        companyForm === null
      ) {
        setConnectionError(true);
        return;
      }

      const tradeRegisterDate =
        fetchedData?.companyInformation?.tradeRegisterDate;

      sessionStorage.setItem("tradeRegisterDate", tradeRegisterDate ?? "");

      const incomeSize: number = Number(getUrlParam("turnover"));
      const turnoverCategory = getRangeData(incomeSize)?.nvFormat ?? "";

      try {
        const res = await createLead({
          variables: {
            input: {
              selectedPackage: getPacketName()
                .toString()
                .toUpperCase() as Packages,
              turnoverCategory: turnoverCategory,
              businessIdentityCode: businessId,
              companyName: companyName,
              companyStreetAddress: companyStreet,
              companyCity: city,
              companyForm: companyForm?.label as string,
              landingPage: getTrackingInfo(strings.TRACKING_LANDINGPAGE),
              organicSource: getTrackingInfo(strings.TRACKING_ORGANICSOURCE),
              organicSourceName: getTrackingInfo(
                strings.TRACKING_ORGANICSOURCENAME
              ),
              trafficSource: getTrackingInfo(strings.TRACKING_TRAFFICSOURCE),
              firstTrafficSource: getTrackingInfo(
                strings.TRACKING_FIRSTTRAFFICSOURCE
              ),
              utmCampaign: getTrackingInfo(strings.TRACKING_UTMCAMPAIGN),
              utmTerm: getTrackingInfo(strings.TRACKING_UTMTERM),
              utmMedium: getTrackingInfo(strings.TRACKING_UTMMEDIUM),
              utmSource: getTrackingInfo(strings.TRACKING_UTMSOURCE),
              utmContent: getTrackingInfo(strings.TRACKING_UTMCONTENT),
              isNewCompanyCampaign: isNewCampaignActivated(),
            },
          },
        });

        if (isSuccessResponse(res.data!.createLead)) {
          sendTag({ status: "leadCreated" });
          const leadId = res.data!.createLead.id;
          sessionStorage.setItem("leadId", leadId);
          setCompanyFound(true);
          setError(undefined);
        } else {
          setConnectionError(true);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      }
    }

    setShowLoading(false);
    setSearchingCompany(false);
  };

  async function setCompanyFormToSelectOptions(companyForm: string) {
    const companyFormOption = companyFormOptions.find(
      (option) => option.companyType == companyForm
    );

    if (companyFormOption === undefined) {
      setIsSupportedCompanyForm(false);
      return;
    }

    const companyFormLabel =
      CompanyForm[companyFormOption.companyType as keyof typeof CompanyForm];

    if (companyFormOption.guid) {
      const companyFormValue = {
        label: t(companyFormLabel.translationKey) ?? "",
        value: companyFormOption.guid ?? "",
      };

      setValue("companyForm", companyFormValue);
      setIsSupportedCompanyForm(true);
      return companyFormValue;
    } else {
      setIsSupportedCompanyForm(false);
      return null;
    }
  }

  const handleFetch = (value: string) => {
    if (value.length === 9) {
      clearErrors("businessIdentityCode");
      setShowLoading(true);
      getCompanyData(value);
    } else {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    const storedValue = sessionStorage.getItem("businessIdentityCode");
    if (storedValue) {
      setValue("businessIdentityCode", storedValue);
      handleFetch(storedValue);
    }
  }, []);

  return (
    <>
      <StyledLayoutItem>
        <InputBlock
          error={errors.businessIdentityCode?.message}
          label={t("CompanyInformation.BusinessId")}
          htmlFor="businessIdentityCode"
        >
          <Input
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            {...register("businessIdentityCode", {
              required: true,
              minLength: 9,
              maxLength: 9,
              pattern: /^[0-9]{7}-[0-9]{1}$/,
              onChange: (e) => {
                setValue("businessIdentityCode", e.target.value);
                handleFetch(e.target.value as string);
              },
            })}
            minLength={9}
            maxLength={9}
            placeholder="1234567-8"
            id="businessIdentityCode"
            trailingContent={
              showLoading ? (
                <Spinner size="tiny" />
              ) : (
                <MagnifierIcon fillColor="info" />
              )
            }
            hasError={errors.businessIdentityCode !== undefined}
          />
        </InputBlock>
        <Typography color="secondary" variant="contentHeadingH5Regular">
          {t("Order.PrhInfo")}
        </Typography>
      </StyledLayoutItem>
    </>
  );
};

export default SelectCompany;

const StyledLayoutItem = styled(Layout.Item)`
  width: 100%;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

function isSuccessResponse(
  response: CreateLead_createLead
): response is CreateLead_createLead {
  return response.__typename === "CreatedLeadResult";
}
