import Cookies from "js-cookie";
import { strings } from "../constants";
import { decodeAndParseToJson } from "./cookieUtil";

export const getOrderCookieData = () => {
  const encodedOrderCookie = Cookies.get(strings.ORDER_DATA_COOKIE_NAME);

  const encodedOrderData =
    encodedOrderCookie && decodeAndParseToJson(encodedOrderCookie);
  const decodedOrderData =
    encodedOrderData && decodeAndParseToJson(encodedOrderData.data);

  return { encodedOrderData, decodedOrderData };
};
