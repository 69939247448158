import {
  Panel,
  PanelHeader,
  PanelSection,
} from "@visma-netvisor/react-component-library";
import styled from "styled-components";

type EmptyPanelProps = {
  hasShorterHeight?: boolean;
};

const EmptyPanel = (props: EmptyPanelProps) => {
  return (
    <StyledPanel hasShorterHeight={props.hasShorterHeight}>
      <PanelHeader heading="" />
      <PanelSection />
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)<{ hasShorterHeight?: boolean }>`
  height: ${({ hasShorterHeight }) =>
    hasShorterHeight ? "16.275rem" : "25rem"};
`;

export default EmptyPanel;
