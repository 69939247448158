import {
  InputLabel,
  Select,
  SelectOption,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { CompanyForm } from "../../Domain/CompanyForm";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";

export const companyFormOptions = Object.entries(CompanyForm).map((item) => {
  return {
    companyType: item[0],
    guid: item[1].id,
    translationKey: item[1].translationKey,
  };
});

export const CompanyFormSelect = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<FormInputs>();

  const options: SelectOption<string>[] = companyFormOptions.map(
    ({ guid, translationKey }) => ({
      value: guid,
      label: t(translationKey),
    })
  );

  return (
    <>
      <InputLabel htmlFor="companyForm" required>
        {t("CompanyInformation.CompanyForm")}
      </InputLabel>
      <Controller
        rules={{ required: true }}
        name="companyForm"
        control={control}
        render={({ field }) => {
          const { ref, ...restOfFields } = field;
          return (
            <Select
              {...restOfFields}
              placeholder={t("CompanyInformation.CompanyFormPlaceholder")}
              refHandle={(ref) => field.ref(ref?.inputRef)}
              options={options}
              id="companyForm"
            />
          );
        }}
      />
    </>
  );
};

export default CompanyFormSelect;
