import { Layout, colorNeutral } from "@visma-netvisor/react-component-library";
import styled from "styled-components";
import EmptyPanel from "./EmptyPanel";
import StatusContent from "./StatusContent";
import NetvisorWhiteLogo from "../logos/NetvisorWhiteLogo";

const Background = () => {
  return (
    <Container>
      <Header>
        <UpperNavBar>
          <Layout.Flex
            as="header"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            styleOverrides={{
              height: 60,
            }}
          >
            <Layout.Flex alignItems="center">
              <StyledLogo />
            </Layout.Flex>
          </Layout.Flex>
        </UpperNavBar>
        <LowerNavBar />
      </Header>
      <Content>
        <Layout.Item columns="span 4">
          <Layout.Flex flexDirection="column">
            <EmptyPanel />
            <EmptyPanel />
          </Layout.Flex>
        </Layout.Item>
        <Layout.Item columns="span 5">
          <Layout.Flex flexDirection="column">
            <EmptyPanel />
            <EmptyPanel />
          </Layout.Flex>
        </Layout.Item>
        <Layout.Item columns="span 3">
          <Layout.Flex flexDirection="column">
            <EmptyPanel hasShorterHeight />
            <EmptyPanel hasShorterHeight />
            <EmptyPanel hasShorterHeight />
          </Layout.Flex>
        </Layout.Item>
      </Content>
      <BackgroundFilter />
      <StatusContent />
    </Container>
  );
};

export default Background;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: ${colorNeutral.backgroundUI};
  display: inherit;
`;

const BackgroundFilter = styled.div`
  background-color: rgb(9, 115, 179);
  opacity: 0.4;
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`;

const Header = styled.div`
  height: 6.5rem; // try to match Netvisor menubar height
  display: flex;
  flex-direction: column;
`;

const StyledLogo = styled(NetvisorWhiteLogo)``;

const UpperNavBar = styled.div`
  flex: 1;
  background-color: rgb(0, 137, 217);
  padding-left: 16px;
`;

const LowerNavBar = styled.div`
  flex: 1;
  background: ${colorNeutral.backgroundPrimary};
`;

const Content = styled(Layout.Grid)`
  padding: 2.5rem 16px 5rem 16px;
`;
