import {
  Checkbox,
  Link,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { getLanguageId } from "../../i18";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { StyledContainer } from "../StyledContainer";
import { StyledInputLabel } from "../StyledInputLabel";
import { isNewCampaignActivated } from "../../Domain/NewCampaign";

export const TermsOfServiceCheckbox = () => {
  const { t } = useTranslation();
  const { register } = useFormContext<FormInputs>();

  const { i18n } = useTranslation();
  const language = i18n.language;
  const languageId = getLanguageId(language);

  const NETVISOR_TERMS_OF_SERVICE =
    languageId === 1
      ? "https://netvisor.fi/media/netvisor-yleiset_sopimusehdot-yritysasiakkaille.pdf"
      : "https://netvisor.fi/media/netvisor-general_terms.pdf";

  const MAVENTA_TERMS_OF_SERVICE = "https://maventa.fi/palveluehdot";
  const NEWCAMPAIGN_TERMS_OF_SERVICE =
    "https://netvisor.fi/download/netvisor-uudet_yritykset_2024-kampanjan_ehdot.pdf";

  return (
    <StyledContainer>
      <Checkbox
        {...register("termsOfServiceCheckbox", { required: true })}
        id="set_terms"
      />
      <StyledInputLabel htmlFor="set_terms">
        <Typography as="span">
          {t("UserInformation.SecondConfirm")}
          <Link target="_blank" href={NETVISOR_TERMS_OF_SERVICE}>
            {t("UserInformation.ServiceTerms")}
          </Link>
          {isNewCampaignActivated() && " ja "}
          {isNewCampaignActivated() && (
            <Link target="_blank" href={NEWCAMPAIGN_TERMS_OF_SERVICE}>
              {t("UserInformation.NewCampaignTerms")}
            </Link>
          )}
          {"."}
          {t("UserInformation.MaventaConfirm")}
          <Link target="_blank" href={MAVENTA_TERMS_OF_SERVICE}>
            {t("UserInformation.MaventaTerms")}
          </Link>
        </Typography>
      </StyledInputLabel>
    </StyledContainer>
  );
};

export default TermsOfServiceCheckbox;
