export const SurveyOptions = {
  Friend: {
    value: "Personal recommendation",
    translationKey: "CompanyInformation.SurveyOptions.Friend",
  },
  AccountOffice: {
    value: "AO recommendation",
    translationKey:
      "CompanyInformation.SurveyOptions.AccountOffice",
  },
  Ad: {
    value: "Ad on another website",
    translationKey: "CompanyInformation.SurveyOptions.Ad",
  },
  Radio: {
    value: "Radio",
    translationKey: "CompanyInformation.SurveyOptions.Radio",
  },
  Event: {
    value: "Event / Webinar",
    translationKey: "CompanyInformation.SurveyOptions.Event",
  },
  SearchEngine: {
    value: "Google / Bing",
    translationKey: "CompanyInformation.SurveyOptions.SearchEngine",
  },
  SocialMedia: {
    value: "Facebook / Instagram",
    translationKey: "CompanyInformation.SurveyOptions.SocialMedia",
  },
  Tv: {
    value: "TV",
    translationKey: "CompanyInformation.SurveyOptions.Tv",
  },
  LinkedIn: {
    value: "LinkedIn",
    translationKey: "CompanyInformation.SurveyOptions.LinkedIn",
  },
  StreamingService: {
    value: "YouTube / Streaming service",
    translationKey: "CompanyInformation.SurveyOptions.StreamingService",
  },
  Other: {
    value: "Other",
    translationKey: "CompanyInformation.SurveyOptions.Other",
  },
};
