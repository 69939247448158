import { Layout } from "@visma-netvisor/react-component-library";
import NetvisorLogo from "./logos/NetvisorLogo";

const Header = () => {
  return (
    <>
      <Layout.Flex
        as="header"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        styleOverrides={{
          height: 60,
          backgroundColor: "white",
        }}
      >
        <Layout.Flex alignItems="center">
          <NetvisorLogo />
        </Layout.Flex>
      </Layout.Flex>
      <Divider />
    </>
  );
};

const Divider: React.FC = () => {
  return (
    <hr
      style={{
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(128, 128, 128, 0.1)",
        border: "none",
        margin: 0,
        padding: 0,
      }}
    />
  );
};

export default Header;
