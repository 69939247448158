import { Layout } from "@visma-netvisor/react-component-library";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { sendPurchaseEvent, sendTag } from "../../analytics";
import { strings } from "../../constants";
import { getOrderCookieData } from "../../utils/getOrderCookieData";
import { logFrontendError } from "../LogFrontendError";
import { useCreateOrder } from "../hooks/useCreateOrder";
import CreationStatusContent from "./CreationStatusContent";
import CreationStatusHeader from "./CreationStatusHeader";

const StatusContent = () => {
  const [companyAlreadyExist, setCompanyAlreadyExist] = useState(false);
  const [activationError, setActivationError] = useState(false);
  const [companyCreatedSuccess, setCompanyCreatedSuccess] = useState(
    sessionStorage.getItem("companyCreationSuccess") === "1"
  );
  const { encodedOrderData, decodedOrderData } = getOrderCookieData();

  const orderId = sessionStorage.getItem(strings.ORDER_ID_COOKIE_NAME) || "";
  const [companyCreationStatus, setCompanyCreationStatus] = useState<
    number | null | undefined
  >(orderId ? strings.CREATION_STATUS_ENUMS.READY_FOR_PROCESSING : null);

  const useCreateOrderMutation = useCreateOrder();

  const createCompany = useCallback(async () => {
    try {
      await useCreateOrderMutation.mutateAsync({
        order: encodedOrderData,
        setCompanyAlreadyExist: setCompanyAlreadyExist,
      });
      setCompanyCreationStatus(
        strings.CREATION_STATUS_ENUMS.READY_FOR_PROCESSING
      );
      sendTag({ status: "authenticationSuccess" });
      sendPurchaseEvent();
    } catch (error) {
      await logFrontendError({
        info: "Order creation failed",
        error,
      });
    }
  }, [useCreateOrderMutation, encodedOrderData]);

  useEffect(() => {
    if (
      companyCreationStatus === null &&
      encodedOrderData &&
      decodedOrderData
    ) {
      createCompany();
    }
  }, []);

  return (
    <CustomLayout>
      <GridContainer>
        <CreationStatusHeader
          isError={companyAlreadyExist || activationError}
        />
        <Layout.Item as="header">
          <CreationStatusContent
            orderId={orderId}
            activationError={activationError}
            companyAlreadyExist={companyAlreadyExist}
            companyCreatedSuccess={companyCreatedSuccess}
            setCompanyAlreadyExist={setCompanyAlreadyExist}
            setActivationError={setActivationError}
            setCompanyCreatedSuccess={setCompanyCreatedSuccess}
          />
        </Layout.Item>
      </GridContainer>
    </CustomLayout>
  );
};

const CustomLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  height: 100%;

  @media (max-width: 768px) {
    width: 95%;
    margin: 10px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 16px;
  padding: 24px;
  max-width: 1015px;
  width: 100%;
  height: 422px;
  background-color: #ffffff;
  border-radius: 10px;

  @media (max-width: 768px) {
    gap: 0px;
    padding: 16px;
    height: 350px;
  }
`;

export default StatusContent;
