import {
  Message,
  MessageVariant,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useState } from "react";

type ErrorProps = {
  title: string;
  message: string;
  variant: MessageVariant | undefined;
};

export const ErrorContent = ({ title, message, variant }: ErrorProps) => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show ? (
        <Message
          fullWidth
          variant={variant}
          closeButtonProps={{
            "aria-label": "Close message",
            onClick: () => setShow(false),
          }}
        >
          <Typography
            as="h4"
            color="unset"
            styleOverrides={{ marginBottom: 8 }}
            variant="headingH4Bold"
          >
            {title}
          </Typography>
          <Typography
            as="p"
            color="unset"
            variant="contentHeadingH4Regular"
            styleOverrides={{ whiteSpace: "pre-line" }}
          >
            {message}
          </Typography>
        </Message>
      ) : null}
    </>
  );
};

export default ErrorContent;
