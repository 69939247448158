import Cookies from "js-cookie";
import { COMPANY_CREATION_URL } from "../constants";

const logoutPath = `${COMPANY_CREATION_URL}/v1/logout`;

/**
 * Decode cookie data and parse it to JSON
 * @param encodedCookieData
 * @returns decoded JSON
 */
export const decodeAndParseToJson = (encodedCookieData: string) => {
  const b64DecodedData = window.atob(encodedCookieData);
  const utf8DecodedData = new TextDecoder("utf-8").decode(
    new Uint8Array(b64DecodedData.split("").map((char) => char.charCodeAt(0)))
  );
  const decodedData = decodeURIComponent(utf8DecodedData);
  return JSON.parse(decodedData);
};

export const cleanBrowserCache = async () => {
  const response = await fetch(logoutPath, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.log(`Logout failed`);
  }

  Cookies.remove("__leadId", { expires: new Date(0), domain: ".netvisor.fi" });
  Cookies.remove("__nv_user_name", {
    expires: new Date(0),
    domain: ".netvisor.fi",
  });
  Cookies.remove("__nv_cc_params", {
    expires: new Date(0),
    domain: ".netvisor.fi",
  });
  sessionStorage.removeItem("leadId");
};
