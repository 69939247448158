import {
  CheckmarkCircleFilledIcon,
  SpamIcon,
} from "@visma-netvisor/nv-react-icons";
import { Layout, Typography } from "@visma-netvisor/react-component-library";
import Cookies from "js-cookie";
import { strings } from "../../constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { cleanBrowserCache } from "../../utils/cookieUtil";

type Props = {
  isError: boolean;
};

const CreationStatusHeader = ({ isError }: Props) => {
  const firstName = Cookies.get(strings.USER_FIRSTNAME_COOKIE_NAME) || "Matti";
  const { t } = useTranslation();

  if (isError) {
    cleanBrowserCache();
  }

  return (
    <Layout.Flex
      alignItems="baseline"
      styleOverrides={{ maxWidth: 400, maxHeight: 96 }}
    >
      <StyledFlex alignItems="center">
        <StyledCheckIconLayout showError={isError}>
          {isError ? (
            <SpamIcon fillColor="error" size={24} />
          ) : (
            <CheckmarkCircleFilledIcon size={24} />
          )}
        </StyledCheckIconLayout>
        {isError ? (
          <Typography variant="contentHeadingH2Semibold">
            {t("OnlineStatus.ActivationError")}
          </Typography>
        ) : (
          <StyledTypography variant="contentHeadingH2Semibold">
            {t("OnlineStatus.Hello")} {firstName},{" "}
            {t("OnlineStatus.WelcomeNetvisor")}
          </StyledTypography>
        )}
      </StyledFlex>
    </Layout.Flex>
  );
};

const StyledCheckIconLayout = styled.div<{ showError?: boolean }>`
  background: ${(props) => (props.showError ? "#FFF2F3" : "#ebf7ff")};
  border-radius: 16px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  min-width: 400px;

  @media (max-width: 768px) {
    font-size: 18px;
    min-width: 200px;
  }
`;

const StyledFlex = styled(Layout.Flex)`
  @media (max-width: 768px) {
    width: 85vw;
  }
`;

export default CreationStatusHeader;
