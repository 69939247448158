import {
  Checkbox,
  Link,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { StyledInputLabel } from "../StyledInputLabel";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { StyledContainer } from "../StyledContainer";

const PRIVACY_STATEMENT_LINK =
  "https://www.visma.com/privacy-statement/finland";

export const PrivacyStatementCheckbox = () => {
  const { t } = useTranslation();
  const { register } = useFormContext<FormInputs>();

  return (
    <StyledContainer>
      <Checkbox
        {...register("privacyPolicyAgreementCheckbox", { required: true })}
        id="privacy_policy"
      />
      <StyledInputLabel htmlFor="privacy_policy">
        <Typography as="p">
          {t("UserInformation.FirstConfirm1")}
          <Link target="_blank" href={PRIVACY_STATEMENT_LINK}>
            {t("UserInformation.PrivacyStatement")}
          </Link>
          {t("UserInformation.FirstConfirm2")}
        </Typography>
      </StyledInputLabel>
    </StyledContainer>
  );
};

export default PrivacyStatementCheckbox;
