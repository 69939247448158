const suffix = process.env["SUFFIX"] ?? "";
const devSuffix = ["-dev", "-stg"];

export const APPID = process.env["APPID"] ?? "netvisor";
export const API_URL = process.env.API_URL || "http://localhost:5153/graphql";
export const COMPANY_INFORMATION_URL = `https://cloud${suffix}.netvisor.fi/api/salesforce/CompanyInformation/GetCompany`;
export const FRONTEND_ERRORLOG_URL = `https://cloud${suffix}.netvisor.fi/api/salesforce/FrontendErrorLog`;

export const COMPANY_CREATION_URL = `https://cloud${suffix}.netvisor.fi/api/company-creation`;
export const NETVISOR_LOGIN_URL = devSuffix.includes(suffix)
  ? "https://jn.nvdev.fi"
  : "https://suomi.netvisor.fi";

export const strings = {
  ORDER_DATA_COOKIE_NAME: "__nv_cc_params",
  ORDER_ID_COOKIE_NAME: "__order_id",
  USER_FIRSTNAME_COOKIE_NAME: "__nv_user_name",
  CREATION_STATUS_ENUMS: {
    REJECTED: 1,
    READY_FOR_PROCESSING: 2,
    PROCESSING: 3,
    CREATED_SUCCESSFULLY: 4,
    FAILED: 5,
  },
  COMPANY_CREATION_ERROR_TYPES: {
    UNDEFINED: 1,
    COMPANY_ALREADY_EXISTS: 2,
  },
  REFETCH_INTERVAL: 5000,
  TRACKING_LANDINGPAGE: "handl_landing_page",
  TRACKING_ORGANICSOURCE: "organic_source",
  TRACKING_ORGANICSOURCENAME: "organic_source_str",
  TRACKING_TRAFFICSOURCE: "traffic_source",
  TRACKING_FIRSTTRAFFICSOURCE: "first_traffic_source",
  TRACKING_UTMCAMPAIGN: "utm_campaign",
  TRACKING_UTMTERM: "utm_term",
  TRACKING_UTMMEDIUM: "utm_medium",
  TRACKING_UTMSOURCE: "utm_source",
  TRACKING_UTMCONTENT: "utm_content",
};
