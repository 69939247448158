import { Message, Typography } from "@visma-netvisor/react-component-library";
import { useState } from "react";

type Props = {
  title: string;
  message: string;
  variant: "success" | "error" | "warning" | "info";
};

export const MessageContainer = ({ title, message, variant }: Props) => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show ? (
        <Message
          fullWidth
          variant={variant}
          closeButtonProps={{
            "aria-label": "Close message",
            onClick: () => setShow(false),
          }}
        >
          <Typography
            as="h4"
            color={variant}
            styleOverrides={{ marginBottom: 8 }}
            variant="headingH4Bold"
          >
            {title}
          </Typography>
          <Typography
            as="p"
            color={variant}
            variant="contentHeadingH4Regular"
            styleOverrides={{ whiteSpace: "pre-line" }}
          >
            {message}
          </Typography>
        </Message>
      ) : null}
    </>
  );
};

export default MessageContainer;
