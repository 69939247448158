import { useMutation } from "react-query";
import { COMPANY_CREATION_URL } from "../../constants";

const netvisorLoginPath = `${COMPANY_CREATION_URL}/v1/netvisorlogin`;

export function useNetvisorLogin() {
  return useMutation(async () => {
    const response = await fetch(netvisorLoginPath, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Network response was not ok, status: ${response.status}`
      );
    }

    return response.json();
  });
}
