import Cookies from "js-cookie";

export function getTrackingInfo(key: string) {
  const value = Cookies.get(key);
  const noReturnValue = "NO_COOKIES";

  if (!value) {
    return noReturnValue;
  }

  if (value?.length == 0) {
    return noReturnValue;
  }

  return value;
}
