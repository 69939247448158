export function getUrlParam(name: string): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
}

export function isBetween(x: number, min: number, max: number): boolean {
  return x >= min && x <= max;
}

export function capitalizeFirstLetter(str: string) {
  return (
    str?.toLowerCase().charAt(0).toUpperCase() + str?.slice(1).toLowerCase()
  );
}
