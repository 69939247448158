import {
  Layout,
  Radio,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  accountingServiceNeed: boolean;
  setAccountingServiceNeed: (value: boolean) => void;
};

const AccountingServiceNeedForm = ({
  accountingServiceNeed,
  setAccountingServiceNeed,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTypography
        as="h3"
        color="secondary"
        variant="headingH3Semibold"
        styleOverrides={{ textAlign: "center" }}
      >
        {t("OnlineStatus.OneMoreQuestion")}
      </StyledTypography>
      <Layout.Flex flexDirection="column" flexGap={0}>
        <StyledTypography
          as="h3"
          color="secondary"
          variant="headingH4Regular"
          styleOverrides={{ paddingTop: 20, paddingBottom: 10 }}
        >
          {t("OnlineStatus.NeedAccountingServices")}
        </StyledTypography>
        <Radio
          label={t("OnlineStatus.PossiblyYesShareContact")}
          name="accountingServiceNeed"
          checked={accountingServiceNeed === true}
          onChange={() => setAccountingServiceNeed(true)}
        />
        <Radio
          label={t("OnlineStatus.NotThisTimeThanks")}
          name="accountingServiceNeed"
          checked={accountingServiceNeed === false}
          onChange={() => setAccountingServiceNeed(false)}
        />
      </Layout.Flex>
    </>
  );
};

export default AccountingServiceNeedForm;

const StyledTypography = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 20px;
    padding-bottom: 80px;
  }
`;
