import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fi from "./translations/fi.json";
import en from "./translations/en.json";
import sv from "./translations/sv.json";

export const languages = ["fi", "en", "sv"];

export const LanguageId = {
  Finnish: 1,
  English: 2,
  Swedish: 3,
};

export const getLanguageId = (translationIndex: string): number => {
  const defaultId = LanguageId.Finnish;
  const index = languages.indexOf(translationIndex);
  if (index === -1) {
    return defaultId;
  }
  return LanguageId[Object.keys(LanguageId)[index] as keyof typeof LanguageId];
};

export function setupI18n(lng = "fi") {
  /** Add other languages to resources here */
  const resources = {
    fi: {
      translation: fi,
    },
    en: {
      translation: en,
    },
    sv: {
      translation: sv,
    },
  };

  i18n.use(initReactI18next).init({
    defaultNS: "translation",
    fallbackLng: "fi",
    supportedLngs: languages,
    interpolation: {
      escapeValue: false,
    },
    lng,
    resources,
  });

  return i18n;
}

export default i18n;
