export const redirectToNetvisor = (url: string, token: string) => {
  const form = document.createElement("form");
  form.method = "post";
  form.action = `${url}/evo/login/openid/authenticationresponsehandler.aspx`;
  form.enctype = "multipart/form-data";

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = "token";
  input.value = token;

  form.appendChild(input);
  document.body.appendChild(form);

  form.submit();
  document.body.removeChild(form);
};
