import {
  Button,
  Link,
  Message,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { StarFilledIcon } from "@visma-netvisor/nv-react-icons";
import { useTranslation } from "react-i18next";
import { getUrlParam } from "../../utils";
import { activateNewCompanyCampaign } from "../../Domain/NewCampaign";

export const AdContainer = () => {
  const { t } = useTranslation();
  const { watch, formState } = useFormContext<FormInputs>();
  const [show, setShow] = useState(true);

  const selectedPackage = getUrlParam("packet");
  const newCompanyInfoLink = "https://netvisor.fi/uudet/";

  return (
    <>
      {show ? (
        <Message
          fullWidth
          variant="info"
          iconOverride={<StarFilledIcon />}
          closeButtonProps={{
            "aria-label": "Close message",
            onClick: () => setShow(false),
          }}
        >
          <Typography
            as="h4"
            color="info"
            styleOverrides={{ marginBottom: 8 }}
            variant="headingH4Bold"
          >
            {selectedPackage === "professional"
              ? t("CompanyInformation.AdCampaignActivatedTitle")
              : t("CompanyInformation.AdTitle")}
          </Typography>
          <Typography
            as="p"
            color="info"
            variant="contentHeadingH4Regular"
            styleOverrides={{ whiteSpace: "pre-line" }}
          >
            {t("CompanyInformation.AdDescription1")}{" "}
            <Link
              target="_blank"
              style={{ cursor: "pointer" }}
              href={newCompanyInfoLink}
            >
              {t("CompanyInformation.AdLink")}
            </Link>
            <br />
            <br />
            {selectedPackage === "professional" ? (
              t("CompanyInformation.AdCampaignActivatedText")
            ) : (
              <Button
                style={{ width: "141px" }}
                variant="tertiary"
                onClick={(e) => {
                  e.preventDefault();
                  activateNewCompanyCampaign(watch(), formState);
                }}
              >
                {t("CompanyInformation.AdChangePacket")}
              </Button>
            )}
          </Typography>
        </Message>
      ) : null}
    </>
  );
};

export default AdContainer;
