import { FRONTEND_ERRORLOG_URL } from "../constants";

export async function logFrontendError(error: any) {
    let errorString = "";

    try {
        errorString = JSON.stringify(error);

        if (errorString === "{}") {
            errorString = error.toString();
        }
    } catch (error) {
        errorString = error.toString();
    }

    try {
        await fetch(FRONTEND_ERRORLOG_URL, {
            method: "POST",
            body: JSON.stringify({ error: errorString }),
            headers: {
                "Content-Type": "application/json",
            }
        });
    } catch (error) {
    }
}