import {
  Button,
  Layout,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputs } from "./Content";

type CreateLeadProps = {
  isLoading: boolean;
};

export const CreateLeadContent = ({ isLoading }: CreateLeadProps) => {
  const { t } = useTranslation();
  const {
    formState: { isValid },
  } = useFormContext<FormInputs>();

  return (
    <>
      <Layout.Flex alignItems="center">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={isLoading}
          style={{ width: "141px" }}
          variant="primary"
        >
          {t("Order.ConfirmOrder")}
        </Button>
        <Typography as="h5" color="secondary" variant="headingH5Regular">
          {t("Order.ConfirmOrderInfo")}
        </Typography>
      </Layout.Flex>
    </>
  );
};

export default CreateLeadContent;
