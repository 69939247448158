import { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import Background from "./Components/CompanyCreationStatus/Background";
import Content from "./Components/Content";
import { getPacketId } from "./Domain/NetvisorPacket";
import { PriceData, fetchPrices } from "./Domain/PriceTable";
import { ToastProvider } from "./context/Toast";
import { PricesContext } from "./pricesContext";
import { getUrlParam } from "./utils";

const params = new URLSearchParams(window.location.search);

const selectedPacketId = getPacketId();

if (
  !(params.has("packet") && params.has("turnover")) &&
  !params.has("status")
) {
  window.location.href = "https://netvisor.fi/hinnoittelu/";
}

if (params.has("packet") && selectedPacketId === null) {
  window.location.href = "https://netvisor.fi/hinnoittelu/";
}

const isCompanyCreation = params.has("status");
sessionStorage.removeItem("tradeRegisterDate");

function App() {
  const [prices, setPrices] = useState<PriceData | null>(null);
  const incomeSize: number = Number(getUrlParam("turnover"));

  useEffect(() => {
    fetchPrices(incomeSize).then(setPrices);
  }, []);

  return (
    <>
      <GlobalStyle />
      <ToastProvider>
        {isCompanyCreation ? (
          <Background />
        ) : (
          <PricesContext.Provider value={prices}>
            <Content />
          </PricesContext.Provider>
        )}
      </ToastProvider>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body * {
  box-sizing: inherit;
}
`;

export default App;
