import { Layout } from "@visma-netvisor/react-component-library";
import { useState } from "react";
import CompanyAlreadyExist from "./CompanyAlreadyExist";
import CompanyCreationFailed from "./CompanyCreationFailed";
import CompanyCreationInProgress from "./CompanyCreationInProgress";
import CompanyCreationSuccess from "./CompanyCreationSuccess";
import ProgressContent from "./ProgressContent";

type CreationStatusContentProps = {
  orderId: string;
  activationError: boolean;
  companyAlreadyExist: boolean;
  companyCreatedSuccess: boolean;
  setCompanyAlreadyExist: (companyExists: boolean) => void;
  setActivationError: (activationError: boolean) => void;
  setCompanyCreatedSuccess: (companyCreated: boolean) => void;
};

const CreationStatusContent = ({
  orderId,
  activationError,
  companyAlreadyExist,
  companyCreatedSuccess,
  setCompanyAlreadyExist,
  setActivationError,
  setCompanyCreatedSuccess,
}: CreationStatusContentProps) => {
  const [progressText, setProgressText] = useState("");

  const progressContentProps = {
    orderId,
    activationError,
    setCompanyAlreadyExist,
    setActivationError,
    setProgressText,
    setCompanyCreatedSuccess,
  };

  const renderCompanyCreation = () => {
    if (companyCreatedSuccess) {
      return <CompanyCreationSuccess />;
    }
    if (companyAlreadyExist) {
      return <CompanyAlreadyExist />;
    }
    return (
      <>
        <ProgressContent {...progressContentProps} />
        {activationError ? (
          <CompanyCreationFailed />
        ) : (
          <CompanyCreationInProgress progressText={progressText} />
        )}
      </>
    );
  };

  return (
    <Layout.Flex flexDirection="column" flexGap={0}>
      {renderCompanyCreation()}
    </Layout.Flex>
  );
};

export default CreationStatusContent;
