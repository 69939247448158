import { Layout, Typography } from "@visma-netvisor/react-component-library";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isNewCampaignActivated } from "../../Domain/NewCampaign";
import { PricesContext } from "../../pricesContext";

interface Props {
  total: string;
}

const PacketPricesSummary: React.FC<Props> = ({ total }) => {
  const { t } = useTranslation();
  const prices = useContext(PricesContext);

  const renderHeader = (text: string) => (
    <Typography as="h4" variant="contentHeadingH4Semibold">
      {text}
    </Typography>
  );

  const renderPrice = (price: string) => (
    <Typography
      as="h4"
      style={{ alignContent: "right" }}
      variant="contentHeadingH4Semibold"
    >
      {price}
    </Typography>
  );

  const renderDescription = (description: string) => (
    <StyledTypography
      variant="contentHeadingH5Regular"
      styleOverrides={{ textAlign: "right" }}
    >
      {description}
    </StyledTypography>
  );

  const renderRow = (headerText: string, priceText: string) => (
    <Layout.Flex style={{ justifyContent: "space-between" }}>
      {renderHeader(headerText)}
      <Layout.Flex alignItems="flex-end" flexGap={0} flexDirection="column">
        {renderPrice(priceText)}
        {renderDescription(t("OrderSummary.PaymentDescription"))}
      </Layout.Flex>
    </Layout.Flex>
  );

  return (
    <>
      <Layout.Item style={{ width: "100%" }}>
        {!prices && (
          <>
            {renderRow("Ongelma hinnan noutamisessa", "— €")}
            <EmptySpace />
          </>
        )}

        {prices && (
          <>
            {renderRow(
              isNewCampaignActivated()
                ? t("OrderInformationDetails.First12Months")
                : t("OrderInformationDetails.FirstMonth"),
              "0,00 €"
            )}
            <EmptySpace />
            {renderRow(
              isNewCampaignActivated()
                ? t("OrderInformationDetails.After12Months")
                : t("OrderInformationDetails.AfterTrial"),
              `${total} € / ${t("OrderSummary.Month")}`
            )}
          </>
        )}
      </Layout.Item>
    </>
  );
};

export default PacketPricesSummary;

const EmptySpace = styled.div`
  height: 16px;
`;

const StyledTypography = styled(Typography)`
  as: h5;
  align-content: right;
  font-size: 12px;
  white-space: normal;

  @media (max-width: 768px) {
    font-size: 9px;
    white-space: nowrap;
  }
`;
