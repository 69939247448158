import {
  Input,
  InputLabel,
  Layout,
  Select,
  SelectOption,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { SurveyOptions } from "../../Domain/SurveyOptions";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";
import { PencilNoteIcon } from "@visma-netvisor/nv-react-icons";
import styled from "styled-components";

const specificKey = "CompanyInformation.SurveyOptions.Other";

// Randomize items in the list, except for the "Other" option
export const surveyOptions = Object.entries(SurveyOptions)
  .map((item) => {
    return {
      value: item[1].value,
      translationKey: item[1].translationKey,
    };
  })
  .sort((a, b) => {
    if (a.translationKey === specificKey) return 1;
    if (b.translationKey === specificKey) return -1;
    return 0.5 - Math.random();
  });

export const SurveySelect = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();
  const surveyAnswer = watch("survey")?.value ?? "";
  const surveyAnswerOther = "Other";

  const options: SelectOption<string>[] = surveyOptions.map(
    ({ value, translationKey }) => ({
      value: value,
      label: t(translationKey),
    })
  );

  return (
    <>
      <StyledLayoutItemSurvey>
        <InputLabel htmlFor="survey">
          {t("CompanyInformation.Survey")}
        </InputLabel>
        <Controller
          name="survey"
          control={control}
          render={({ field }) => {
            const { ref, ...restOfFields } = field;
            return (
              <Select
                {...restOfFields}
                placeholder={t("CompanyInformation.SurveyPlaceholder")}
                refHandle={(ref) => field.ref(ref?.inputRef)}
                options={options}
                id="survey"
              />
            );
          }}
        />
      </StyledLayoutItemSurvey>
      {surveyAnswer === surveyAnswerOther && (
        <>
          <StyledLayoutItemSurvey>
            <InputLabel htmlFor="surveyOther" required>
              {t("CompanyInformation.SurveyOther")}
            </InputLabel>
            <Input
              {...register("surveyOther", {
                required: true,
              })}
              id="surveyOther"
              trailingContent={<PencilNoteIcon fillColor="info" />}
              hasError={errors.surveyOther !== undefined}
            />
          </StyledLayoutItemSurvey>
        </>
      )}
    </>
  );
};

const StyledLayoutItemSurvey = styled(Layout.Item)`
  @media (max-width: 768px) {
    width: 90vw;
    padding-bottom: 16px;
  }
`;

export default SurveySelect;
