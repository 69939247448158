import { InfoCircleIcon } from "@visma-netvisor/nv-react-icons";
import {
  Layout,
  Link,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { isNewCampaignActivated } from "../../Domain/NewCampaign";

const OrderInformationDetails = () => {
  const { t } = useTranslation();
  const SUPPORT_PAGE = "https://netvisor.fi/tuote/yhteydenotto/";

  return (
    <Layout.Item styleOverrides={{ width: "100%" }}>
      <Layout.Flex
        alignItems="center"
        styleOverrides={{ paddingBottom: "12px" }}
      >
        <InfoCircleIcon />
        <Typography as="h4" variant="contentHeadingH4Semibold">
          {t("OrderInformationDetails.Trial")}
        </Typography>
      </Layout.Flex>

      <Typography
        as="p"
        variant="contentHeadingH4Regular"
        styleOverrides={{
          paddingBottom: "16px",
        }}
      >
        {isNewCampaignActivated()
          ? t("OrderInformationDetails.First12MonthsFree")
          : t("OrderInformationDetails.FirstMonthFree")}
      </Typography>
      <Typography
        as="p"
        variant="contentHeadingH4Regular"
        styleOverrides={{
          paddingBottom: "16px",
        }}
      >
        {t("OrderInformationDetails.SecondMonth")}
      </Typography>
      <Typography as="p" variant="contentHeadingH4Regular">
        {t("OrderSummary.ContactSalesText")}{" "}
        <Link target="_blank" style={{ cursor: "pointer" }} href={SUPPORT_PAGE}>
          {t("OrderSummary.ContactSalesLink")}
        </Link>
      </Typography>
    </Layout.Item>
  );
};

export default OrderInformationDetails;
