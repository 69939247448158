import { Link, Typography } from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { sendTag } from "../../analytics";
import { cleanBrowserCache } from "../../utils/cookieUtil";
import styled from "styled-components";

const CompanyAlreadyExist = () => {
  const { t } = useTranslation();
  sendTag({ status: "companyAlreadyExists" });

  cleanBrowserCache();

  return (
    <>
      <TypographyCompanyRegistered color="error" variant="headingH3Bold">
        {t("OnlineStatus.CompanyRegistered")}
      </TypographyCompanyRegistered>
      <TypographyCompanyInNetvisor color="primary">
        {t("OnlineStatus.CompanyInNetvisor")}
      </TypographyCompanyInNetvisor>
      <TypographyLoginOrContact>
        {t("OnlineStatus.LoginOrContact")}
      </TypographyLoginOrContact>
      <StyledLink
        href="https://support.netvisor.fi/fi/support/home"
        target="_blank"
      >
        {t("OnlineStatus.CustomerService")}
      </StyledLink>
    </>
  );
};

export default CompanyAlreadyExist;

const TypographyCompanyRegistered = styled(Typography)`
  text-align: center;
  margin-top: 24px;

  @media (max-width: 768px) {
  }
`;

const TypographyCompanyInNetvisor = styled(Typography)`
  text-align: center;
  padding-top: 24px;
  margin-left: 35%;
  margin-right: 35%;

  @media (max-width: 768px) {
    padding-top: 8px;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const TypographyLoginOrContact = styled(Typography)`
  text-align: center;
  padding-top: 24px;
  margin-left: 20%;
  margin-right: 20%;

  @media (max-width: 768px) {
  }
`;

const StyledLink = styled(Link)`
  text-align: center;
  padding-top: 70px;

  @media (max-width: 768px) {
    padding-top: 8px;
  }
`;
