import { Layout, Typography } from "@visma-netvisor/react-component-library";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isNewCompany } from "../../Domain/NewCampaign";
import { FormInputs } from "../Content";
import CreateLeadContent from "../CreateLeadContent";
import ErrorContent from "../ErrorContent";
import WarningContent from "../WarningContent";
import { AdContainer } from "./AdContainer";
import CheckboxLayout from "./CheckboxLayout";
import CompanyInformation from "./CompanyInformation";
import MessageContainer from "./MessageContainer";
import SelectCompany from "./SelectCompany";
import UserInformation from "./UserInformation";

type OrderProps = {
  error: string | undefined;
  setError: (error: string | undefined) => void;
  isLoading: boolean;
};

export const OrderConfirmation = (props: OrderProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<FormInputs>();

  const [isSupportedCompanyForm, setIsSupportedCompanyForm] =
    useState<boolean>(true);
  const [companyFound, setCompanyFound] = useState<boolean>(true);
  const [searchingCompany, setSearchingCompany] = useState<boolean>(true);
  const [connectionError, setConnectionError] = useState<boolean>(false);

  const isValidBusinessId = errors.businessIdentityCode === undefined;

  const params = new URLSearchParams(window.location.search);
  const isNewCompanyCampaign = params.has("newcompanycampaign");

  const showCompanyFound =
    isSupportedCompanyForm &&
    isValidBusinessId &&
    !searchingCompany &&
    !connectionError &&
    !props.error;

  const showNotSupportedError =
    !isSupportedCompanyForm &&
    companyFound &&
    !searchingCompany &&
    !connectionError;

  const showCompanyInformation =
    (isValidBusinessId &&
      companyFound &&
      !showNotSupportedError &&
      !searchingCompany) ||
    connectionError;

  const showNewCompanyCampaignInfo =
    isNewCompanyCampaign && !showCompanyInformation;

  return (
    <>
      <StyledLayoutFlex>
        <Typography as="h1" variant="contentHeadingH1Bold">
          {t("Order.Title")}
        </Typography>

        {showNewCompanyCampaignInfo && (
          <Typography as="h4" variant="contentHeadingH4Bold">
            <MessageContainer
              title={t("Order.NewCompanyCampaignTitle")}
              message={t("Order.NewCompanyCampaignDescription")}
              variant={"info"}
            />
          </Typography>
        )}

        <Typography as="h4" variant="contentHeadingH4Bold">
          {t("Order.CompanySearch")}
        </Typography>

        <SelectCompany
          setIsSupportedCompanyForm={setIsSupportedCompanyForm}
          setCompanyFound={setCompanyFound}
          setSearchingCompany={setSearchingCompany}
          setConnectionError={setConnectionError}
          setError={props.setError}
        />

        {showCompanyFound && (
          <MessageContainer
            title={t("CompanyInformation.CompanyFoundTitle")}
            message={t("CompanyInformation.CompanyFoundDescription")}
            variant={"success"}
          />
        )}

        {showCompanyFound && isNewCompany() && <AdContainer />}

        {props.error && (
          <ErrorContent
            title={t("Errors.General.Title")}
            message={t("Errors.General.Description")}
            variant={"error"}
          />
        )}

        {showNotSupportedError && (
          <ErrorContent
            title={t("Errors.NotSupportedCompanyForm.Title")}
            message={t("Errors.NotSupportedCompanyForm.Message")}
            variant={"info"}
          />
        )}

        {!companyFound &&
          !searchingCompany &&
          !connectionError &&
          !props.error && (
            <ErrorContent
              title={t("Errors.CompanyNotFound.Title")}
              message={t("Errors.CompanyNotFound.Message")}
              variant={"info"}
            />
          )}

        {connectionError && (
          <WarningContent
            title={t("Errors.ConnectionError.Title")}
            message={t("Errors.ConnectionError.Message")}
          />
        )}

        {showCompanyInformation && (
          <>
            <CompanyInformation />
            <UserInformation />
            <CheckboxLayout />
            <CreateLeadContent isLoading={props.isLoading} />
          </>
        )}
      </StyledLayoutFlex>
    </>
  );
};

export default OrderConfirmation;

const StyledLayoutFlex = styled(Layout.Flex)`
  width: 600px;
  flex-direction: column;

  @media (max-width: 768px) {
    width: auto;
  }
`;
