import { ProgressBar } from "@visma-netvisor/react-component-library";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { strings } from "../../constants";
import { COMPANY_CREATION_URL } from "../../constants";
import { logFrontendError } from "../LogFrontendError";

type Props = {
  orderId: string;
  activationError: boolean;
  setCompanyAlreadyExist: React.Dispatch<React.SetStateAction<boolean>>;
  setActivationError: React.Dispatch<React.SetStateAction<boolean>>;
  setProgressText: React.Dispatch<React.SetStateAction<string>>;
  setCompanyCreatedSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

type OrderStatus = {
  status: number;
  errorType: number;
  progressPercentage: number;
};

const ProgressContent = ({
  orderId,
  activationError,
  setCompanyAlreadyExist,
  setActivationError,
  setProgressText,
  setCompanyCreatedSuccess,
}: Props) => {
  const [progress, setProgress] = useState(0);

  const updateProgressText = (percentage: number) => {
    if (percentage >= 75) {
      setProgressText("OnlineStatus.ActivatingMaventa");
    } else if (percentage >= 50) {
      setProgressText("OnlineStatus.DefiningSettings");
    } else if (percentage >= 25) {
      setProgressText("OnlineStatus.CreatingUser");
    } else if (percentage >= 0) {
      setProgressText("OnlineStatus.CreatingCompany");
    }
  };

  useEffect(() => {
    if (!orderId) return;

    const interval = setInterval(async () => {
      try {
        const result: OrderStatus | null = await getOrderStatus(orderId);

        if (!result) {
          return;
        }

        const status = result.status;
        const errorType = result.errorType ? result.errorType : 0;
        const progressPercentage = result.progressPercentage;

        setProgress(progressPercentage);
        updateProgressText(progressPercentage);

        if (status === strings.CREATION_STATUS_ENUMS.CREATED_SUCCESSFULLY) {
          setCompanyCreatedSuccess(true);
          sessionStorage.setItem("companyCreationSuccess", "1");
        }

        if (
          errorType ===
          strings.COMPANY_CREATION_ERROR_TYPES.COMPANY_ALREADY_EXISTS
        ) {
          clearInterval(interval);
          setCompanyAlreadyExist(true);
          return;
        }

        if (
          status === strings.CREATION_STATUS_ENUMS.REJECTED ||
          status === strings.CREATION_STATUS_ENUMS.FAILED
        ) {
          clearInterval(interval);
          setActivationError(true);
          return;
        }
      } catch (error) {
        clearInterval(interval);
        await logFrontendError({
          info: "Company activation error",
          error,
        });
        setActivationError(true);
        return;
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [orderId]);

  return (
    <CustomProgressBar
      progress={progress}
      showIndicator
      variant={activationError ? "error" : "primary"}
    />
  );
};

async function getOrderStatus(orderId: string): Promise<OrderStatus | null> {
  try {
    const response = await fetch(
      `${COMPANY_CREATION_URL}/v1/orders/${orderId}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return (await response.json()) as OrderStatus;
  } catch (error) {
    await logFrontendError({
      info: "Fetching order status failed",
      error,
    });
    console.error(`Fetch error: ${error}`);
    return null;
  }
}

const CustomProgressBar = styled(ProgressBar)`
  .nv-react__progressbar {
    height: 12.8125px;
    margin: 20px;
    border-radius: 6.40625px;

    @media (max-width: 768px) {
      height: 9px;
      margin: 12px;
    }
  }
  .nv-react__progressbar__progress-indicator {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 19.2188px;
    line-height: 19px;
    color: #6a6c6d;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export default ProgressContent;
