import { useEffect } from 'react';
import { APPID } from './constants';
import { newTracker, trackPageView } from '@snowplow/browser-tracker';

const analyticsDisabled = true;
const snowPlowUrl = 'https://snowplow.visma.com';

export function sendTag(data: any) {
  if (analyticsDisabled) {
    return;
  }

  const virtualPageUrl = location.pathname + location.search;
  const params = new URLSearchParams(virtualPageUrl.substring(virtualPageUrl.indexOf('?')));

  data.event = "virtualPageview";
  data.virtualPageUrl = virtualPageUrl;
  data.packet = params.get('packet');

  trackPageView({
    title: 'Netvisor Online',
    context: [
      {
        schema: 'iglu:org.schema/WebPage/jsonschema/1-0-0',
        data: data,
      },
    ],
  });
}

export function sendPurchaseEvent() {
  if (analyticsDisabled) {
    sessionStorage.removeItem("purchaseInfo");
    return;
  }

  const sessionItem = sessionStorage.getItem("purchaseInfo");
  const purchaseInfo = JSON.parse(sessionItem || "");

  trackPageView({
    title: 'Netvisor Online',
    context: [
      {
        schema: 'iglu:org.schema/WebPage/jsonschema/1-0-0',
        data: {
          event: "purchase",
          ecommerce: {
            value: purchaseInfo.subTotal,
            tax: purchaseInfo.vat,
            currency: "EUR",
            items: [
              {
                item_id: purchaseInfo.packet,
                item_name: purchaseInfo.packet,
                item_brand: "Netvisor",
                price: purchaseInfo.total,
              }
            ]
          }
        },
      },
    ],
  });

  sessionStorage.removeItem("purchaseInfo");
}

export const LoadAnalytics = () => {
  useEffect(() => {
    if (analyticsDisabled) {
      return;
    }

    newTracker('cf', snowPlowUrl, {
      appId: APPID,
      platform: 'web',
      contexts: {
        webPage: true,
      }
    });

    sendTag({ status: "formOpened" });
  }, []);

  return null;
};