import { logFrontendError } from "../Components/LogFrontendError";
import { capitalizeFirstLetter, isBetween } from "../utils";

type PriceRange = {
  min: number;
  max: number;
  description: string;
  nvPacketIdentifier: string;
  nvFormat: string;
  hinnoitteluFormat: string;
};

export interface PriceData {
  Basic: number;
  Core: number;
  Professional: number;
  Premium: number;
}

// prettier-ignore
const incomeRanges: PriceRange[] = [
  { min: 0, max: 100000, description: "100 000", nvPacketIdentifier: "AD622A24-6835-483A-9A7B-08961C1A2D14", nvFormat: "alle 0,1 Meur", hinnoitteluFormat: "1" },
  { min: 100000, max: 200000, description: "100 000 - 200 000", nvPacketIdentifier: "C6A9FA4D-58E8-4F00-A276-B3DC790FF158", nvFormat: "0,1 - 0,2 Meur", hinnoitteluFormat: "2" },
  { min: 200000, max: 500000, description: "200 000 - 500 000", nvPacketIdentifier: "0926670C-5FD4-4327-B29C-306BE0077408", nvFormat: "0,2 - 0,5 Meur", hinnoitteluFormat: "3" },
  { min: 500000, max: 1000000, description: "500 000 - 1 000 000", nvPacketIdentifier: "A8D7B65B-FA96-4057-8B73-DFF2BD81A604", nvFormat: "0,5 - 1,0 Meur", hinnoitteluFormat: "4" },
  { min: 1000000, max: 2000000, description: "1 000 000 - 2 000 000", nvPacketIdentifier: "A6631DD2-C2EC-47C0-9AEA-78A66C166A35", nvFormat: "1 - 2,0 Meur", hinnoitteluFormat: "5" },
  { min: 2000000, max: 5000000, description: "2 000 000 - 5 000 000", nvPacketIdentifier: "47792E23-98AD-4E0F-B376-52C5057FC177", nvFormat: "2,0 - 5,0 Meur", hinnoitteluFormat: "6" },
  { min: 5000000, max: 10000000, description: "5 000 000 - 10 000 000", nvPacketIdentifier: "58AB676D-9088-4A0D-9D45-53A87B111FA0", nvFormat: "5,0 - 10,0 Meur", hinnoitteluFormat: "7" },
  { min: 10000000, max: 25000000, description: "10 000 000 - 25 000 000", nvPacketIdentifier: "75F5CC9E-77A9-4500-B40F-94454E9447E7", nvFormat: "10,0 - 25,0 Meur", hinnoitteluFormat: "8" },
  { min: 25000000, max: 50000000, description: "25 000 000 - 50 000 000", nvPacketIdentifier: "A47E3505-36BF-45FF-8661-3B9DEAA291C4", nvFormat: "25,0 - 50,0 Meur", hinnoitteluFormat: "9" },
  { min: 50000000, max: 100000000, description: "50 000 000 - 100 000 000", nvPacketIdentifier: "C6D16251-D074-4AC6-8A79-D9A411588212", nvFormat: "50 - 100 Meur", hinnoitteluFormat: "10" },
  { min: 100000000, max: Infinity, description: "100 000 000", nvPacketIdentifier: "771653CF-C8AB-4D61-A3A2-4DB9B2B075C3", nvFormat: "Yli 100 Meur", hinnoitteluFormat: "11" },
];

export function getPriceByPacket(
  prices: PriceData | null,
  packet: string | null
) {
  if (prices === null || packet === null) {
    return 0;
  }

  return prices[capitalizeFirstLetter(packet) as keyof typeof prices];
}

export async function fetchPrices(
  priceRange: number
): Promise<PriceData | null> {
  const turnoverType = getRangeData(priceRange)?.hinnoitteluFormat ?? "0";

  const data = new FormData();
  data.append("action", "nv_pricing");
  data.append("liikevaihto", turnoverType);

  let responseJson: any = {};

  try {
    const response = await fetch(
      "https://netvisor.fi/netvisor-buy-pricing/nv-excel.php",
      { method: "POST", body: data }
    );
    responseJson = await response.json();
  } catch (error) {
    await logFrontendError({ info: "Failed to fetch prices", error });
    return null;
  }

  return {
    Basic:
      responseJson["basic-sale-percent"] > 0
        ? responseJson["basic-sale"]
        : responseJson.basic,
    Core:
      responseJson["core-sale-percent"] > 0
        ? responseJson["core-sale"]
        : responseJson.core,
    Professional:
      responseJson["professional-sale-percent"] > 0
        ? responseJson["professional-sale"]
        : responseJson.professional,
    Premium:
      responseJson["premium-sale-percent"] > 0
        ? responseJson["premium-sale"]
        : responseJson.premium,
  };
}

export function getRangeData(incomeSize: number): PriceRange | null {
  for (const range of incomeRanges) {
    if (isBetween(incomeSize, range.min, range.max)) {
      return range;
    }
  }

  return null;
}
