import { Packages } from "../globalTypes";
import { capitalizeFirstLetter, getUrlParam } from "../utils";

export const NetvisorPacket = {
  Basic: "EAF88F8F-FD31-4E3E-ABA2-2A8C25D1C3BD",
  Core: "D2CCDD1D-266B-4F9E-922A-EBDFBF1D1EBF",
  Professional: "4E5E6986-596F-42AB-B210-399FFCF5FB06",
  //Premium: "3129E620-3C5F-44B6-BA82-EDB66E92F034",
  Light: "8c2eb09b-f41f-4950-b6ed-d1f0fdc9bf6c",
};

export const getPacketId = (): string | null => {
  const packetName = getPacketName().toString();
  const packet: { [key: string]: string } = NetvisorPacket;
  return packet.hasOwnProperty(packetName) ? packet[packetName] : null;
};

export const getPacketName = () => {
  const sessionPacket = capitalizeFirstLetter(getUrlParam("packet") ?? "");
  return (sessionPacket as Packages) ?? null;
};
