import { Layout, Typography } from "@visma-netvisor/react-component-library";

interface SummaryPricingDetailsProps {
  description: string;
  value: string;
}

const SummaryPricingDetails = (props: SummaryPricingDetailsProps) => {
  return (
    <Layout.Item styleOverrides={{ width: "100%" }}>
      <Layout.Flex styleOverrides={{ justifyContent: "space-between" }}>
        <Typography as="h4" variant="contentHeadingH4Regular">
          {props.description}
        </Typography>
        <Typography as="h4" variant="contentHeadingH4Regular">
          {props.value}
        </Typography>
      </Layout.Flex>
    </Layout.Item>
  );
};

export default SummaryPricingDetails;
