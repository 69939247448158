"use client";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { setupI18n, languages } from "./i18";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { createGlobalStyle } from "styled-components";
import { API_URL } from "./constants";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { getOrderCookieData } from "./utils/getOrderCookieData";
import { LoadAnalytics } from "./analytics";
import { Maintenance } from "./Components/Maintenance";
import { ErrorBoundary } from "react-error-boundary";
import { SomethingWentWrong } from "./Components/SomethingWentWrong";
import { logFrontendError } from "./Components/LogFrontendError";

const GlobalStyle = createGlobalStyle`
  #appRoot {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;

const pathLanguage = languages.find((lng) =>
  location.pathname.includes(`/${lng}`)
);
const { decodedOrderData } = getOrderCookieData();
const languageFromCookie = languages[decodedOrderData?.LanguageId - 1];
const lang = pathLanguage || "fi";

const i18n = languageFromCookie
  ? setupI18n(languageFromCookie)
  : setupI18n(lang);

if (languageFromCookie) {
  const url = new URL(location.href);
  url.pathname = `/${languageFromCookie}`;
  window.history.replaceState(null, "", url);
}

if (lang !== pathLanguage) {
  const url = new URL(location.href);
  url.pathname = `/${lang}`;
  window.history.replaceState(null, "", url);
}

const buildFetchFn = (sourceIdentifier: string) =>
  async function nvFetch(url: string, options?: RequestInit) {
    const opts: RequestInit = options ?? {};

    if (sourceIdentifier) {
      if (!opts.headers) {
        opts.headers = {};
      }

      opts.headers = {
        ...opts.headers,
        "X-Netvisor-Source": sourceIdentifier,
      };
    }

    return await fetch(url, opts);
  };

const httpLink = createHttpLink({
  uri: API_URL,
  fetch: buildFetchFn("salesforce"),
});

const client = new ApolloClient({
  link: httpLink,
  uri: API_URL,
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

const rootElement = document.getElementById("appRoot")!;
const reactRoot = createRoot(rootElement);
const isMaintenance = false;

const logError = (error: Error, info: { componentStack: string }) => {
  logFrontendError({ error, info });
};

reactRoot.render(
  <React.StrictMode>
    {isMaintenance ? (
      <Maintenance />
    ) : (
      <>
        <ErrorBoundary
          FallbackComponent={SomethingWentWrong}
          onError={logError}
        >
          <LoadAnalytics />
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <I18nextProvider i18n={i18n}>
                <GlobalStyle />
                <App />
              </I18nextProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </>
    )}
  </React.StrictMode>
);
