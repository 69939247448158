import { Typography } from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";

type Props = {
  progressText: any;
};

const CompanyCreationInProgress = ({ progressText }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        color="secondary"
        styleOverrides={{
          textAlign: "center",
          marginTop: "24px",
        }}
      >
        {t(progressText)}
      </Typography>
      <Typography
        color="secondary"
        styleOverrides={{
          textAlign: "center",
        }}
      >
        {t("OnlineStatus.WaitWithoutClosingBrowser")}
      </Typography>
    </>
  );
};

export default CompanyCreationInProgress;
