import { Link, Typography } from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import { sendTag } from "../../analytics";
import { cleanBrowserCache } from "../../utils/cookieUtil";
import styled from "styled-components";

const CompanyCreationFailed = () => {
  const { t } = useTranslation();
  sendTag({ status: "companyCreationFailed" });

  cleanBrowserCache();

  return (
    <>
      <CenteredTypography variant="headingH4Regular">
        {t("OnlineStatus.ActivationFailed")}
      </CenteredTypography>
      <CenteredPrimaryTypography color="primary">
        {t("OnlineStatus.ContactCustomerService")}
        {": "}
        <Link href="mailto:tuki.netvisor@visma.com" target="_blank">
          tuki.netvisor@visma.com
        </Link>{" "}
        {t("OnlineStatus.WorkHoursPhone")}{" "}
        <PhoneNumber>010 5058 490</PhoneNumber> ({t("OnlineStatus.CallCost")})
      </CenteredPrimaryTypography>
    </>
  );
};

export default CompanyCreationFailed;

const CenteredTypography = styled(Typography)`
  text-align: center;
  margin-top: 24px;
`;

const CenteredPrimaryTypography = styled(Typography)`
  text-align: center;
  padding-top: 24px;
  margin-left: 20%;
  margin-right: 20%;

  @media (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const PhoneNumber = styled.b`
  font-weight: bold;
`;
