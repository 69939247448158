import { Layout } from "@visma-netvisor/react-component-library";
import PrivacyStatementCheckbox from "./PrivacyStatementCheckbox";
import TermsOfServiceCheckbox from "./TermsOfServiceCheckbox";
import AuthorizedRepresentativeCheckbox from "./AuthorizedRepresentativeCheckbox";
import styled from "styled-components";
import { SurveySelect } from "./SurveySelect";

export const CheckboxLayout = () => {
  return (
    <>
      <SurveySelect />
      <StyledLayoutFlex flexDirection="column" flexGap={8}>
        <Layout.Flex flexDirection="row" flexGap={8}>
          <AuthorizedRepresentativeCheckbox />
        </Layout.Flex>
        <Layout.Flex flexDirection="row" flexGap={8}>
          <PrivacyStatementCheckbox />
        </Layout.Flex>
        <Layout.Flex flexDirection="row" flexGap={8}>
          <TermsOfServiceCheckbox />
        </Layout.Flex>
      </StyledLayoutFlex>
    </>
  );
};

export default CheckboxLayout;

const StyledLayoutFlex = styled(Layout.Flex)`
  @media (max-width: 768px) {
    width: 90vw;
  }
`;
