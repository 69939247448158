export const packetFunctionalityDetails = {
  Basic: [
    "PacketDetails.Basic.AccountingFunctions",
    "PacketDetails.Basic.Registry",
    "PacketDetails.Basic.PurchaseInvoiceProcessing",
    "PacketDetails.Basic.DirectPayment",
    "PacketDetails.Basic.ElectronicTax",
    "PacketDetails.Basic.EInvoice",
  ],
  Core: [
    "PacketDetails.Core.AccountingFunctions",
    "PacketDetails.Core.Registry",
    "PacketDetails.Core.SalesEInvoice",
    "PacketDetails.Core.PurchaseEInvoice",
    "PacketDetails.Core.PurchaseInvoiceProcessing",
    "PacketDetails.Core.DirectPayment",
    "PacketDetails.Core.ElectronicTax",
  ],
  Professional: [
    "PacketDetails.Professional.AccountingFunctions",
    "PacketDetails.Professional.Registry",
    "PacketDetails.Professional.EInvoice",
    "PacketDetails.Professional.PurchaseInvoiceProcessing",
    "PacketDetails.Professional.DirectPayment",
    "PacketDetails.Professional.ElectronicTax",
    "PacketDetails.Professional.Reporting",
    "PacketDetails.Professional.Integration",
  ],
  Premium: [
    "PacketDetails.Premium.AccountingFunctions",
    "PacketDetails.Premium.Registry",
    "PacketDetails.Premium.EInvoice",
    "PacketDetails.Premium.PurchaseInvoiceProcessing",
    "PacketDetails.Premium.DirectPayment",
    "PacketDetails.Premium.ElectronicTax",
    "PacketDetails.Premium.Reporting",
    "PacketDetails.Premium.Integration",
  ],
};
