import { PropsWithChildren, createContext, useContext } from "react";
import { useToast as _useToast } from "@visma-netvisor/react-component-library";

type Alert = "info" | "success" | "warning" | "error";
type ToastContextType = {
  addToast: (message: string, variant: Alert) => void;
};
const ToastContext = createContext<ToastContextType>(null!);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const { toasts, addToast: _addToast } = _useToast();

  const addToast = (message: string, variant?: Alert) => {
    _addToast(message, {
      variant,
    });
  };

  const value: ToastContextType = {
    addToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {toasts}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
