export const CompanyForm = {
  Osakeyhtiö: {
    id: "bcebf020-105d-4b26-bbe8-3bcaf73832dc",
    translationKey: "CompanyInformation.CompanyFormDescriptions.LimitedCompany",
  },
  Kommandiittiyhtiö: {
    id: "e1deff8d-2a22-43a4-8640-8c90ed4d672f",
    translationKey:
      "CompanyInformation.CompanyFormDescriptions.LimitedPartnership",
  },
  "Avoin yhtiö": {
    id: "013b5b69-b139-4623-8a2b-84ffac417b8e",
    translationKey: "CompanyInformation.CompanyFormDescriptions.OpenCompany",
  },
  "Yksityinen elinkeinonharjoittaja": {
    id: "309ff196-57b8-4572-a148-ea797ddadc6a",
    translationKey: "CompanyInformation.CompanyFormDescriptions.TradeName",
  },
};
