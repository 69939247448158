import { WrenchIcon } from "@visma-netvisor/nv-react-icons";
import { Button, EmptyState } from "@visma-netvisor/react-component-library";

export const SomethingWentWrong = () => {
  return (
    <>
      <EmptyState
        actions={
          <>
            <Button
              onClick={() =>
                (window.location.href = "https://netvisor.fi/hinnoittelu/")
              }
            >
              Takaisin
            </Button>
          </>
        }
        description={"Something went wrong"}
        heading="Something went wrong"
        icon={WrenchIcon}
      />
    </>
  );
};
