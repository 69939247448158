import { useMutation } from "react-query";
import { strings, COMPANY_CREATION_URL } from "../../constants";
import { sendTag } from "../../analytics";

type CreateOrderInput = {
  order: OrderRequest;
  setCompanyAlreadyExist: (companyExists: boolean) => void;
};

type OrderRequest = {
  data: string;
};

export function useCreateOrder() {
  return useMutation(async (createOrderInput: CreateOrderInput) => {
    const orderPath = `${COMPANY_CREATION_URL}/v1/orders`;

    const response = await fetch(orderPath, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createOrderInput.order),
    });

    const data = await response.json();

    if (!response.ok) {
      if (data.error === "CompanyAlreadyExists") {
        sendTag({ status: "companyAlreadyExists" });
        createOrderInput.setCompanyAlreadyExist(true);
        return;
      }
    }

    if (data.identifier) {
      sessionStorage.setItem(strings.ORDER_ID_COOKIE_NAME, data.identifier);
    } else {
      throw new Error("Identifier was not found");
    }

    return data;
  });
}
