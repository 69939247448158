import React, { SVGProps } from "react";

const NetvisorLogo: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="180"
      height="30"
      viewBox="0 0 400 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.547 25.1753C109.445 24.6293 111.912 24.1092 114.95 23.6169C117.986 23.1245 121.349 22.8786 125.037 22.8786C128.507 22.8786 131.409 23.358 133.741 24.3145C136.072 25.2715 137.931 26.6112 139.314 28.3336C140.696 30.0566 141.672 32.1358 142.242 34.5687C142.811 37.0023 143.096 39.6688 143.096 42.567V66.6849H133.253V44.1255C133.253 41.8287 133.103 39.874 132.806 38.26C132.507 36.6473 132.019 35.3347 131.341 34.3223C130.663 33.311 129.741 32.5727 128.576 32.1075C127.409 31.6434 125.985 31.4099 124.305 31.4099C123.057 31.4099 121.755 31.4925 120.4 31.6564C119.044 31.8203 118.041 31.9577 117.391 32.0662V66.6849H107.547V25.1753Z"
        fill="#003253"
      />
      <path
        d="M150.62 45.4381C150.62 41.6642 151.175 38.3562 152.287 35.5116C153.398 32.6689 154.876 30.3025 156.721 28.4162C158.564 26.5292 160.679 25.1076 163.065 24.1499C165.451 23.1941 167.892 22.7147 170.387 22.7147C176.244 22.7147 180.812 24.5474 184.094 28.211C187.374 31.8752 189.015 37.3449 189.015 44.6178C189.015 45.1651 189.001 45.7801 188.974 46.4635C188.946 47.1481 188.906 47.7631 188.852 48.3091H160.788C161.059 51.7546 162.265 54.421 164.408 56.3074C166.549 58.1943 169.655 59.1378 173.722 59.1378C176.108 59.1378 178.29 58.9196 180.27 58.4815C182.25 58.0446 183.809 57.5793 184.947 57.0869L186.249 65.2084C185.706 65.4826 184.96 65.7698 184.013 66.0699C183.063 66.3706 181.979 66.6436 180.759 66.8901C179.538 67.136 178.223 67.3412 176.813 67.5051C175.402 67.6691 173.966 67.7516 172.502 67.7516C168.76 67.7516 165.506 67.1902 162.74 66.0699C159.975 64.9496 157.697 63.3905 155.907 61.3938C154.117 59.3978 152.788 57.0456 151.921 54.3385C151.053 51.6313 150.62 48.6653 150.62 45.4381V45.4381ZM179.172 41.0079C179.172 39.6417 178.982 38.342 178.603 37.1114C178.223 35.8807 177.667 34.8146 176.935 33.9125C176.203 33.0097 175.308 32.2997 174.251 31.779C173.193 31.2601 171.932 31.0001 170.468 31.0001C168.949 31.0001 167.621 31.2873 166.482 31.8616C165.343 32.4353 164.38 33.1878 163.594 34.117C162.808 35.0476 162.197 36.1143 161.764 37.3166C161.329 38.5201 161.032 39.7507 160.869 41.0079H179.172V41.0079Z"
        fill="#003253"
      />
      <path
        d="M196.695 12.4604L206.537 10.8193V23.7808H221.668V32.0662H206.537V49.5398C206.537 52.9852 207.079 55.4465 208.164 56.923C209.249 58.3995 211.093 59.1378 213.696 59.1378C215.485 59.1378 217.072 58.9467 218.455 58.5634C219.837 58.1814 220.935 57.8252 221.749 57.4973L223.376 65.3723C222.237 65.8647 220.745 66.3706 218.902 66.8901C217.058 67.409 214.888 67.6691 212.394 67.6691C209.356 67.6691 206.822 67.2593 204.788 66.439C202.755 65.6182 201.14 64.4289 199.948 62.8704C198.755 61.3119 197.914 59.425 197.427 57.2102C196.939 54.9948 196.695 52.4663 196.695 49.6217V12.4604V12.4604Z"
        fill="#003253"
      />
      <path
        d="M242.164 66.6849C239.441 61.0524 236.65 54.585 233.791 47.2837C230.931 39.9825 228.333 32.1482 225.991 23.7808H236.489C237.087 26.1878 237.781 28.7724 238.571 31.5331C239.36 34.2951 240.204 37.0578 241.102 39.8185C242.001 42.5812 242.926 45.2612 243.88 47.8581C244.833 50.4561 245.718 52.7942 246.535 54.8721C247.351 52.7942 248.223 50.4561 249.148 47.8581C250.073 45.2612 250.986 42.5812 251.885 39.8185C252.782 37.0578 253.64 34.2951 254.457 31.5331C255.273 28.7724 255.981 26.1878 256.581 23.7808H266.752C264.41 32.1482 261.81 39.9825 258.952 47.2837C256.092 54.585 253.302 61.0524 250.579 66.6849H242.164H242.164Z"
        fill="#003253"
      />
      <path
        d="M283.286 11.2298C283.286 13.0896 282.689 14.5661 281.497 15.6593C280.303 16.7544 278.894 17.3004 277.267 17.3004C275.585 17.3004 274.147 16.7544 272.955 15.6593C271.762 14.5661 271.166 13.0896 271.166 11.2298C271.166 9.31571 271.762 7.81269 272.955 6.71762C274.147 5.62441 275.585 5.07718 277.267 5.07718C278.894 5.07718 280.303 5.62441 281.497 6.71762C282.689 7.81269 283.286 9.3157 283.286 11.2298ZM282.229 66.6849H272.386V23.7808H282.229V66.6849V66.6849Z"
        fill="#003253"
      />
      <path
        d="M303.605 59.5482C306.209 59.5482 308.106 59.2339 309.299 58.6047C310.492 57.9762 311.089 56.8959 311.089 55.3639C311.089 53.9428 310.451 52.767 309.178 51.8365C307.903 50.9072 305.802 49.896 302.873 48.8015C301.084 48.1452 299.443 47.4476 297.952 46.7094C296.46 45.9711 295.172 45.1096 294.088 44.1255C293.003 43.1413 292.148 41.9514 291.526 40.5568C290.901 39.1622 290.59 37.454 290.59 35.4296C290.59 31.4925 292.026 28.3891 294.901 26.1188C297.775 23.8504 301.68 22.7147 306.615 22.7147C309.109 22.7147 311.496 22.9476 313.774 23.4117C316.051 23.8769 317.76 24.328 318.899 24.7656L317.109 32.8044C316.024 32.3127 314.641 31.8616 312.96 31.4512C311.279 31.0408 309.326 30.8362 307.103 30.8362C305.096 30.8362 303.469 31.1782 302.223 31.8616C300.974 32.5456 300.351 33.5982 300.351 35.0199C300.351 35.731 300.474 36.3602 300.718 36.9062C300.962 37.454 301.381 37.9599 301.979 38.424C302.575 38.8892 303.362 39.3545 304.338 39.8185C305.313 40.2838 306.506 40.762 307.917 41.2544C310.248 42.1295 312.228 42.991 313.855 43.8383C315.482 44.6869 316.824 45.6433 317.882 46.7094C318.939 47.7761 319.712 48.9938 320.2 50.36C320.688 51.7274 320.932 53.3685 320.932 55.2819C320.932 59.3837 319.427 62.4883 316.417 64.5928C313.408 66.6979 309.109 67.7516 303.524 67.7516C299.782 67.7516 296.772 67.4361 294.495 66.8082C292.217 66.1796 290.616 65.6737 289.695 65.2904L291.404 57.005C292.868 57.607 294.617 58.1814 296.65 58.7274C298.684 59.2752 301.002 59.5482 303.605 59.5482V59.5482Z"
        fill="#003253"
      />
      <path
        d="M366.786 45.1922C366.786 48.5834 366.298 51.6726 365.322 54.4617C364.345 57.2509 362.962 59.6302 361.173 61.5991C359.383 63.5673 357.227 65.0993 354.706 66.1925C352.184 67.2864 349.404 67.8336 346.368 67.8336C343.33 67.8336 340.565 67.2864 338.071 66.1925C335.576 65.0993 333.434 63.5673 331.644 61.5991C329.855 59.6302 328.458 57.2509 327.455 54.4617C326.451 51.6726 325.95 48.5834 325.95 45.1922C325.95 41.8016 326.451 38.7253 327.455 35.9633C328.458 33.2019 329.867 30.8362 331.685 28.8673C333.501 26.8984 335.657 25.3806 338.152 24.3145C340.646 23.2477 343.385 22.7147 346.368 22.7147C349.35 22.7147 352.103 23.2477 354.625 24.3145C357.146 25.3806 359.302 26.8984 361.092 28.8673C362.881 30.8362 364.277 33.2019 365.281 35.9633C366.284 38.7253 366.786 41.8016 366.786 45.1922V45.1922ZM356.699 45.1922C356.699 40.9259 355.79 37.5501 353.974 35.0605C352.156 32.5727 349.622 31.3279 346.368 31.3279C343.114 31.3279 340.578 32.5727 338.762 35.0605C336.945 37.5501 336.037 40.9259 336.037 45.1922C336.037 49.5127 336.945 52.9316 338.762 55.4465C340.578 57.9626 343.114 59.2197 346.368 59.2197C349.622 59.2197 352.156 57.9626 353.974 55.4465C355.79 52.9316 356.699 49.5127 356.699 45.1922V45.1922Z"
        fill="#003253"
      />
      <path
        d="M397.453 32.7225C396.639 32.4495 395.513 32.1623 394.077 31.8616C392.639 31.5615 390.971 31.4099 389.074 31.4099C387.988 31.4099 386.837 31.5202 385.617 31.7383C384.396 31.9577 383.542 32.1481 383.054 32.3127V66.6849H373.211V25.8316C375.109 25.1217 377.482 24.4513 380.329 23.8221C383.176 23.1941 386.335 22.8786 389.806 22.8786C390.457 22.8786 391.215 22.9193 392.084 23.0019C392.951 23.0838 393.818 23.1941 394.687 23.3297C395.553 23.4671 396.395 23.631 397.209 23.8221C398.022 24.0143 398.673 24.1912 399.161 24.3551L397.453 32.7225V32.7225Z"
        fill="#003253"
      />
      <path
        d="M54.0929 33.2689V33.2688C40.9425 25.0858 27.6586 16.7644 22.5984 21.8707L22.1994 22.2719C18.6864 26.673 23.4463 36.2403 29.6242 46.6594C30.809 48.6578 32.0459 50.6873 33.2866 52.7178C46.4642 60.9208 59.7829 69.2846 64.864 64.1709C69.8064 59.1867 61.9885 46.2258 54.0929 33.2689V33.2689Z"
        fill="#0095F8"
      />
      <path
        d="M22.1994 22.2719L22.1988 22.2725L13.9898 30.5266L3.19575 41.4365C3.08879 41.5197 2.91022 41.6756 2.70733 41.8803C-3.80982 48.4525 11.829 68.8289 20.7295 85L30.759 74.8856C33.5908 72.0301 35.3845 68.2947 35.8498 64.2838C36.3151 60.2724 35.4248 56.2205 33.3231 52.7828L33.2866 52.7178C32.0459 50.6873 30.809 48.6578 29.6242 46.6594C23.4463 36.2403 18.6864 26.673 22.1994 22.2719H22.1994Z"
        fill="#005F9E"
      />
      <path
        d="M66.7329 1L56.7069 11.0969C53.8571 13.9516 52.0474 17.6936 51.5713 21.7158C51.0953 25.738 51.9809 29.8045 54.0842 33.2553L54.0929 33.2688V33.2689C61.9885 46.2258 69.8065 59.1867 64.864 64.1709L84.6855 44.1887L84.6864 44.1878L84.7405 44.1333C91.2731 37.5281 75.6231 17.1745 66.7329 1V1Z"
        fill="#02D1E4"
      />
      <path
        d="M84.686 44.1885C84.7041 44.1709 84.7232 44.1547 84.7412 44.1367C84.7233 44.1546 84.7044 44.1707 84.6864 44.1881L84.6855 44.189L84.686 44.1885H84.686Z"
        fill="#1400FF"
      />
      <path
        d="M54.0929 33.2689L54.0929 33.2688V33.2688C40.9425 25.0857 27.6585 16.7643 22.5984 21.8707L22.1994 22.2719C18.6864 26.673 23.4463 36.2403 29.6242 46.6594C30.809 48.6578 32.0459 50.6873 33.2866 52.7178C46.4642 60.9208 59.783 69.2846 64.864 64.1709C69.8065 59.1867 61.9885 46.2258 54.0929 33.2689V33.2689Z"
        fill="#0095F8"
      />
      <path
        d="M33.3231 52.7828L33.2865 52.7178L33.2405 52.6891C20.804 44.9191 8.44207 37.3518 3.19576 41.4364C3.0888 41.5197 2.91019 41.6757 2.70733 41.8803C-3.80981 48.4525 11.829 68.8289 20.7295 85L30.759 74.8857C33.5908 72.0302 35.3845 68.2947 35.8498 64.2837C36.3151 60.2724 35.4247 56.2204 33.3231 52.7828L33.3231 52.7828Z"
        fill="#005F9E"
      />
      <path
        d="M29.6241 46.6594C23.4463 36.2403 18.6863 26.673 22.1994 22.2719L22.1987 22.2725L13.9897 30.5266L3.19574 41.4365C8.44205 37.3518 20.8039 44.9191 33.2405 52.6891L33.2865 52.7178C32.0459 50.6873 30.809 48.6578 29.6241 46.6594V46.6594Z"
        fill="#007ACA"
      />
      <path
        d="M66.7329 1.00003L56.7069 11.097C53.8571 13.9517 52.0474 17.6936 51.5713 21.7159C51.0953 25.738 51.9809 29.8045 54.0842 33.2554L54.0928 33.2688L54.0932 33.269C66.9399 41.1681 79.7051 49.0624 84.686 44.1882L84.7405 44.1333C91.2731 37.5281 75.6231 17.1746 66.7329 1.00003L66.7329 1.00003Z"
        fill="#64E5DB"
      />
      <path
        d="M54.0936 33.2692L54.0928 33.2688V33.2688L54.0936 33.2692Z"
        fill="#64E5DB"
      />
      <path
        d="M84.7411 44.1367C84.7232 44.1547 84.7041 44.1709 84.686 44.1885L84.6855 44.189C84.7038 44.1712 84.723 44.1549 84.7411 44.1367Z"
        fill="#02D1E4"
      />
      <path
        d="M54.0932 33.269L54.0928 33.2688L54.0929 33.2689C61.9884 46.2258 69.8064 59.1867 64.864 64.1709L84.6855 44.1887L84.686 44.1882C79.7051 49.0624 66.9399 41.1681 54.0932 33.269V33.269Z"
        fill="#02D1E4"
      />
    </svg>
  );
};

export default NetvisorLogo;
