import {
  CheckmarkCircleFilledIcon,
  CoinEuroIcon,
} from "@visma-netvisor/nv-react-icons";
import { Layout, Typography } from "@visma-netvisor/react-component-library";
import { packetFunctionalityDetails } from "../../Domain/PacketFunctionalityDetails";
import { useTranslation } from "react-i18next";

interface PacketFunctionalityDetailsProps {
  packet: string;
}

const PacketFunctionalityDetails = (props: PacketFunctionalityDetailsProps) => {
  return (
    <>
      <Layout.Item styleOverrides={{ width: "100%" }}>
        <PacketDetailsList packet={props.packet} />
      </Layout.Item>
    </>
  );
};

interface PacketDetailsListProps {
  packet: string;
}

const PacketDetailsList = ({ packet }: PacketDetailsListProps) => (
  <>
    {(packetFunctionalityDetails as { [key: string]: string[] })[packet].map(
      (item: string, index: number) => (
        <PacketDetailsListItem key={index} text={item} />
      )
    )}
  </>
);

interface PacketDetailsListItemProps {
  text: any;
}

const PacketDetailsListItem = ({ text }: PacketDetailsListItemProps) => {
  const { t } = useTranslation();
  const translationKey = text ?? "";
  const showEuroIcon = translationKey === "PacketDetails.Basic.EInvoice";
  return (
    <>
      <Layout.Flex
        justifyContent="left"
        alignItems="center"
        styleOverrides={{ paddingBottom: "8px" }}
      >
        {showEuroIcon ? <CoinEuroIcon /> : <CheckmarkCircleFilledIcon />}
        <Typography as="h4" variant="contentHeadingH4Regular">
          {t(translationKey)}
        </Typography>
      </Layout.Flex>
    </>
  );
};

export default PacketFunctionalityDetails;
