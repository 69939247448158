import {
  Typography,
  Layout,
  InputLabel,
  Input,
} from "@visma-netvisor/react-component-library";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormInputs } from "../Content";

export const UserInformation = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*\.[a-zA-Z]{2,}$/;
  const PHONE_REGEX = /^\+?\d{7,12}$/;

  return (
    <>
      <Typography as="h4" variant="contentHeadingH4Bold">
        {t("UserInformation.Title")}
      </Typography>

      <StyledLayoutItem>
        <InputLabel htmlFor="FirstName" required>
          {t("UserInformation.FirstName")}
        </InputLabel>
        <Input
          {...register("firstName", { required: true })}
          id="FirstName"
          hasError={errors.firstName !== undefined}
        />
        {errors.firstName && (
          <Typography color="error" variant="headingH5Regular">
            {t("Errors.RequiredField", {
              requiredField: t("UserInformation.FirstName"),
            })}
          </Typography>
        )}
      </StyledLayoutItem>

      <StyledLayoutItem>
        <InputLabel htmlFor="lastName" required>
          {t("UserInformation.LastName")}
        </InputLabel>
        <Input
          {...register("lastName", { required: true })}
          id="lastName"
          hasError={errors.lastName !== undefined}
        />
        {errors.lastName && (
          <Typography color="error" variant="headingH5Regular">
            {t("Errors.RequiredField", {
              requiredField: t("UserInformation.LastName"),
            })}
          </Typography>
        )}
      </StyledLayoutItem>

      <StyledLayoutItem>
        <InputLabel htmlFor="email" required>
          {t("UserInformation.Email")}
        </InputLabel>
        <Input
          {...register("email", {
            pattern: EMAIL_REGEX,
            required: true,
          })}
          id="email"
          hasError={errors.email !== undefined}
        />
        {errors.email && (
          <Typography color="error" variant="headingH5Regular">
            {t("Errors.InvalidEmail")}
          </Typography>
        )}
      </StyledLayoutItem>

      <StyledLayoutItem>
        <InputLabel htmlFor="phone" required>
          {t("UserInformation.Phone")}
        </InputLabel>
        <Input
          {...register("phone", {
            pattern: PHONE_REGEX,
            required: true,
          })}
          id="phone"
          hasError={errors.phone !== undefined}
        />
        {errors.phone ? (
          <Typography color="error" variant="headingH5Regular">
            {t("Errors.InvalidPhone")}
          </Typography>
        ) : (
          <Typography color="secondary" variant="headingH5Regular">
            {t("UserInformation.PhoneFormat")}
          </Typography>
        )}
      </StyledLayoutItem>
    </>
  );
};

export default UserInformation;

const StyledLayoutItem = styled(Layout.Item)`
  @media (max-width: 768px) {
    width: 90vw;
  }
`;
