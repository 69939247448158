import {
  Layout,
  Link,
  Typography,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SummaryPricingDetails from "./SummaryPricingDetails";
import { getPriceByPacket, getRangeData } from "../../Domain/PriceTable";
import { isNewCampaignActivated } from "../../Domain/NewCampaign";
import { capitalizeFirstLetter, getUrlParam } from "../../utils";
import { useContext, useState } from "react";
import { Packages } from "../../globalTypes";
import PacketFunctionalityDetails from "./PacketFunctionalityDetails";
import { ChevronDownIcon, ChevronUpIcon } from "@visma-netvisor/nv-react-icons";
import PacketPricesSummary from "./PacketPricesSummary";
import OrderInformationDetails from "./OrderInformationDetails";
import { PricesContext } from "../../pricesContext";
import CampaignLogo from "../logos/campaign.png";

const PACKET_CHOICE_PAGE = "https://netvisor.fi/hinnoittelu/";

export const OrderSummary = () => {
  const { t } = useTranslation();
  const [showPacketDetails, setShowPacketDetails] = useState(false);
  const packetType: Packages = (getUrlParam("packet") as Packages) ?? null;
  const incomeSize: number = Number(getUrlParam("turnover"));
  const prices = useContext(PricesContext);
  const orderPrice = getPriceByPacket(prices, getUrlParam("packet"));
  const priceValueDescription = getRangeData(incomeSize)?.description;
  const rangeCriteria = incomeSize < 100000 ? t("OrderSummary.Under") : "";
  const vatAlv = 0.255;
  const vatSum = formatNumberWithCommas(orderPrice * vatAlv);
  const total = formatNumberWithCommas(orderPrice + orderPrice * vatAlv);
  const subTotal = formatNumberWithCommas(orderPrice);

  sessionStorage.setItem(
    "purchaseInfo",
    JSON.stringify({
      total: orderPrice + orderPrice * vatAlv,
      subTotal: orderPrice,
      vat: orderPrice * vatAlv,
      packet: packetType,
    })
  );

  const pricingDetails = {
    Subtotal: [
      t("OrderSummary.Subtotal"),
      `${subTotal} € /` + t("OrderSummary.Month"),
    ],
    VAT: [t("OrderSummary.VAT"), `${vatSum} € /` + t("OrderSummary.Month")],
    OpeningCost: [t("OrderSummary.OpeningCost"), "0,00 €"],
    DeliveryMethod: [
      t("OrderSummary.DeliveryMethod"),
      t("OrderSummary.Electronic"),
    ],
    PaymentMethod: [t("OrderSummary.PaymentMethod"), t("OrderSummary.Invoice")],
  };

  return (
    <>
      <Frame
        style={{ position: "relative", marginTop: "8px", marginRight: "8px" }}
      >
        {isNewCampaignActivated() && (
          <CampaignIconDiv>
            <img
              src={CampaignLogo}
              alt="Campaign Logo"
              style={{ width: "96px" }}
            />
            <CampaignText>{t("OrderSummary.Campaign")}</CampaignText>
          </CampaignIconDiv>
        )}
        <StyledLayoutFlex alignItems="flex-start" flexDirection="column">
          <Layout.Flex
            alignItems="center"
            flexGap={42}
            justifyContent={"space-between"}
            styleOverrides={{ width: "100%" }}
          >
            <Typography as="h2" variant="contentHeadingH2Semibold">
              {t("Order.Summary")}
            </Typography>
          </Layout.Flex>

          <Typography
            as="h2"
            styleOverrides={{ color: "#0071BD" }}
            variant="contentHeadingH1Bold"
          >
            {capitalizeFirstLetter(packetType)}
          </Typography>

          <Typography
            as="h3"
            styleOverrides={{ color: "#003253" }}
            variant="headingH3Semibold"
          >
            {t("OrderSummary.Description", {
              rangeCriteria: rangeCriteria,
              descriptionValue: priceValueDescription,
            })}
          </Typography>

          <Divider />

          <Layout.Flex
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => setShowPacketDetails(!showPacketDetails)}
          >
            {showPacketDetails ? <ChevronUpIcon /> : <ChevronDownIcon />}
            <Typography as="h4" variant="headingH4Semibold">
              {t("OrderSummary.PacketIncludes")}
            </Typography>
          </Layout.Flex>

          {showPacketDetails && (
            <>
              <PacketFunctionalityDetails
                packet={capitalizeFirstLetter(packetType)}
              />
              <Layout.Flex alignItems="center">
                <Link style={{ cursor: "pointer" }} href={PACKET_CHOICE_PAGE}>
                  {t("OrderSummary.ChangePackage")}
                </Link>
                <Typography
                  as="h5"
                  color="tertiaryMuted"
                  variant="contentHeadingH5Regular"
                >
                  {t("OrderSummary.PacketCanBeChanged")}
                </Typography>
              </Layout.Flex>
            </>
          )}

          <Divider />

          {Object.keys(pricingDetails).map(
            (key: keyof typeof pricingDetails, index: number) => (
              <SummaryPricingDetails
                key={index}
                description={pricingDetails[key][0]}
                value={pricingDetails[key][1]}
              />
            )
          )}

          <Divider />

          <PacketPricesSummary total={total} />

          <DarkDivider />

          <OrderInformationDetails />
        </StyledLayoutFlex>
      </Frame>
    </>
  );
};

const Divider = styled("hr")`
  width: 420px;
  border: 1px solid #e1e8ed;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const DarkDivider = styled("hr")`
  width: 420px;
  border: 1px solid #001e33;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const Frame = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
  width: 482px;
  border-top: 5px solid #0095f8;
  border-radius: 12px 12px 0 0;
  flex: none;
  order: 1;
  flex-grow: 0;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const StyledLayoutFlex = styled(Layout.Flex)`
  width: 482px;
  background: #ffffff;
  padding: 24px;
  border: 1px solid #c7d7e2;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const CampaignIconDiv = styled.div`
  width: 96px;
  height: 96px;
  position: absolute;
  top: -13px;
  right: -8px;
`;

const CampaignText = styled.p`
  transform: rotate(45deg);
  position: relative;
  top: -88px;
  left: 19px;
  color: #fee;
`;

export function formatNumberWithCommas(number: number): string {
  const roundedNumber = Math.round(number * 100) / 100;
  const [integerPart, decimalPart] = roundedNumber.toFixed(2).split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    " "
  );
  const formattedDecimalPart = decimalPart || "00";
  return `${formattedIntegerPart},${formattedDecimalPart}`;
}

export default OrderSummary;
