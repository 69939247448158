import { Checkbox } from "@visma-netvisor/react-component-library";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputs } from "../Content";

export const AuthorizedRepresentativeCheckbox = () => {
  const { t } = useTranslation();
  const { register } = useFormContext<FormInputs>();

  return (
    <Checkbox
      {...register("authorizedRepresentativeCheckbox", { required: true })}
      label={t("UserInformation.AuthorizativeConfirm")}
      id="authorized_representative"
    />
  );
};

export default AuthorizedRepresentativeCheckbox;
