import { PencilNoteIcon } from "@visma-netvisor/nv-react-icons";
import {
  Layout,
  InputLabel,
  Input,
  InputBlock,
} from "@visma-netvisor/react-component-library";
import { useTranslation } from "react-i18next";
import CompanyFormSelect from "./CompanyFormSelect";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "../Content";

export const CompanyInformation = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const postalInputFields = (
    <>
      <StyledLayoutItemPostal>
        <InputLabel htmlFor="postalCode" required>
          {t("CompanyInformation.PostalCode")}
        </InputLabel>
        <Input
          {...register("postalCode", {
            required: true,
            pattern: /^(?! )[^\s].*$/,
          })}
          id="postalCode"
          trailingContent={<PencilNoteIcon fillColor="info" />}
          hasError={errors.postalCode !== undefined}
        />
      </StyledLayoutItemPostal>
      <StyledLayoutItemPostal>
        <InputLabel htmlFor="city" required>
          {t("CompanyInformation.City")}
        </InputLabel>
        <Input
          {...register("city", { required: true, pattern: /^(?! )[^\s].*$/ })}
          id="city"
          trailingContent={<PencilNoteIcon fillColor="info" />}
          hasError={errors.city !== undefined}
        />
      </StyledLayoutItemPostal>
    </>
  );

  return (
    <>
      <StyledLayoutItem>
        <CompanyFormSelect />
      </StyledLayoutItem>
      <StyledLayoutItem>
        <InputLabel htmlFor="companyName" required>
          {t("CompanyInformation.CompanyName")}
        </InputLabel>
        <Input
          {...register("companyName", {
            required: true,
            pattern: /^(?! )[^\s].*$/, // No whitespace at the beginning
          })}
          id="companyName"
          trailingContent={<PencilNoteIcon fillColor="info" />}
          hasError={errors.companyName !== undefined}
        />
      </StyledLayoutItem>
      <StyledLayoutItem>
        <InputBlock
          htmlFor="address"
          required
          label={t("CompanyInformation.Address")}
          error={errors.address?.message}
        >
          <Input
            {...register("address", {
              required: true,
              pattern: /^(?! )[^\s].*$/,
              maxLength: 150,
            })}
            maxLength={150}
            id="address"
            trailingContent={<PencilNoteIcon fillColor="info" />}
            hasError={errors.address !== undefined}
          />
        </InputBlock>
      </StyledLayoutItem>
      <FlexContainer>{postalInputFields}</FlexContainer>
    </>
  );
};

export default CompanyInformation;

const StyledLayoutItem = styled(Layout.Item)`
  width: 100%;

  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const StyledLayoutItemPostal = styled(Layout.Item)`
  width: 300px;

  @media (max-width: 768px) {
    width: 90vw;
    padding-bottom: 16px;
  }
`;

const FlexContainer = styled(Layout.Flex)`
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;
